import React, { useState, useEffect } from "react";
import axios from "axios";

const AddStudent = () => {

    const [email, setEmail] = useState("");
    const [dropdownData, setDropdownData] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const [savedData, setSavedData] = useState([]);
    const [userDetails, setUserDetails] = useState(null);
    const [error, setError] = useState("");

    // Fetch dropdown data (simulate or use API)
    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const result = await axios.get("https://innovationschool.io:5000/api/moduledata", {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                setDropdownData(result.data);
                //consol.log(result.data);
            } catch (error) {
                //consol.log("the error is ", error);
                if (error.response && error.response.status === 401) {
                    // navigate("/");
                }
            }
        };
        fetchContacts();
    }, []);

    const handleFind = async () => {
        setError(""); // Clear previous errors
        setUserDetails(null); // Clear previous user data

        if (!email) {
            setError("Please enter an email.");
            return;
        }

        try {
            const response = await axios.get(`https://innovationschool.io:5000/api/students/${email}`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem('token')}`, // Include token if needed
                },
            });
            setUserDetails(response.data[0]); // Set user data
        } catch (err) {
            setError(err.response?.data?.error || "Error fetching user data.");
        }
    };

    const handleAdd = async () => {
        if (!email || !selectedOption) {
            alert("Please fill in all fields.");
            return;
        }

        const newData = { email, selectedOption };
        try {
            const response = await axios.post(`https://innovationschool.io:5000/api/add-course`, { email, selectedOption }, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem('token')}`, // Include token if needed
                },
            });

            // console.log(response);
            alert(response?.data?.message)

        }
        catch (err) {
            setError("somthing went wrong");
        }

        // Reset form
        setEmail("");
        setSelectedOption("");
        console.log("Data saved:", newData);
    };
    return (
        <div className="max-w-lg mx-auto mt-10 p-6 bg-white shadow-xl rounded-lg border border-gray-200">
        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">Update Student With Courses</h2>
      
        {/* Email Input */}
        <div className="flex items-center mb-5 gap-2">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email"
            className="flex-1 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
          <button
            onClick={handleFind}
            className="px-4 py-3 bg-blue-500 text-white font-medium rounded-lg shadow-md hover:bg-blue-600 transition"
          >
            Find
          </button>
        </div>
      
        {/* Error Message */}
        {error && (
          <p className="text-red-600 bg-red-50 p-3 rounded-lg mb-4 text-sm border border-red-300">
            {error}
          </p>
        )}
      
        {/* User Details */}
        {userDetails && (
          <div className="mb-6 p-4 bg-gray-50 border border-gray-200 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-700 mb-2">Student Details:</h3>
            <p className="text-gray-600">
              <strong className="text-gray-700">Name:</strong> {userDetails.name}
            </p>
            <p className="text-gray-600">
              <strong className="text-gray-700">Email:</strong> {userDetails.email}
            </p>
          </div>
        )}
      
        {/* Dropdown */}
        <div className="mb-5">
          <select
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          >
            <option value="">Select a course</option>
            {dropdownData.map((item) => (
              <option key={item._id} value={item._id}>
                {item.title}
              </option>
            ))}
          </select>
        </div>
      
        {/* Add Button */}
        <button
          onClick={handleAdd}
          className="w-full p-3 bg-green-500 text-white font-medium rounded-lg shadow-md hover:bg-green-600 transition"
        >
          Add
        </button>
      
        {/* Saved Data */}
        {savedData.length > 0 && (
          <div className="mt-8">
            <h3 className="text-lg font-semibold text-gray-800 mb-3">Saved Data:</h3>
            <ul className="space-y-2">
              {savedData.map((item, index) => (
                <li
                  key={index}
                  className="p-3 bg-gray-50 border border-gray-200 rounded-lg text-sm text-gray-600"
                >
                  <strong className="text-gray-700">Email:</strong> {item.email}, <strong className="text-gray-700">Selected Option ID:</strong> {item.selectedOption}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      
    )

}
export default AddStudent