import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles

function EditBlogs() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [image, setimge] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);

  const [keywords, setkeywords] = useState('');
  const [url, setUrl] = useState('');
  const [author, setAuthor] = useState('innovation school');
  const [metatitle, setMetaTitle] = useState('');
  const [metadesc, setMetadis] = useState('');
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');
  const [tag, setTag]=useState('');
  const [deadline, setdeadline]=useState('')

  const fetchCategories = async () => {
    try {
      const response = await axios.get('/api/categories');
      setCategories(response.data.data);
    } catch (error) {
      console.error('Error fetching categories:', error.response?.data?.message || error.message);
    }
  };

  useEffect(() => {
    if (id) {
      axios
        .get(`https://innovationschool.io:5000/api/blogs/${id}`, {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`, // Include token if needed
          },
        })
        .then((response) => {
          setTitle(response.data.title);
          setContent(response.data.content);
          setimge(response.data.cardImage);
          setkeywords(response.data?.keywords);
          setUrl(response.data?.url);
          setAuthor(response.data?.author);
          setMetaTitle(response.data?.metatitle);
          setMetadis(response.data?.metadesc);
          setCategory(response.data?.category);
          setTag(response.data?.tag)
          setdeadline(response.data?.deadline)
        })
        .catch((error) => {
          console.error('There was an error fetching the blog!', error);
        });
    }
    fetchCategories();
  }, [id]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const blogData = {
      title,
      content,
      cardImage: image,
      keywords,
      url,
      author,
      metatitle,
      metadesc,
      category,
      deadline,
      tag,
    };

    const apiEndpoint = id
      ? `https://innovationschool.io:5000/api/blogs/${id}`
      : '/api/blogs';

    axios[id ? 'put' : 'post'](apiEndpoint, blogData, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      // .then(() => navigate('/dashboard'))
      .catch((error) => {
        console.error('There was an error saving the blog!', error);
      });
  };

  const handleFileChangeAndUpload = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      alert('Please select a file first.');
      return;
    }

    setLoading(true); // Start loading indicator
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post(
        'https://innovationschool.io:5000/api/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setimge(response.data.fileUrl); // Update user with uploaded image URL
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setLoading(false); // Stop loading indicator
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <h1 className="text-3xl font-bold text-gray-800 mb-8">
        {id ? 'Edit Blog' : 'Create Blog'}
      </h1>
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-lg">
        <div className="mb-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">SEO Details</h2>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">Meta Title</label>
            <input
              type="text"
              value={metatitle}
              onChange={(e) => setMetaTitle(e.target.value)}
              placeholder="Enter your meta title"
              className="w-full px-4 py-2 border rounded-md text-gray-700 focus:ring focus:ring-blue-300"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">Meta Description</label>
            <textarea
              value={metadesc}
              onChange={(e) => setMetadis(e.target.value)}
              placeholder="Enter your meta description"
              className="w-full px-4 py-2 border rounded-md text-gray-700 focus:ring focus:ring-blue-300"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">Meta keywords</label>
            <input
              type="text"
              value={keywords}
              onChange={(e) => setkeywords(e.target.value)}
              placeholder="Enter your meta title"
              className="w-full px-4 py-2 border rounded-md text-gray-700 focus:ring focus:ring-blue-300"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">Author</label>
            <input
              type="text"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
              placeholder="Enter the author name"
              className="w-full px-4 py-2 border rounded-md text-gray-700 focus:ring focus:ring-blue-300"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Custom URL</label>
            <input
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Enter your custom URL"
              className="w-full px-4 py-2 border rounded-md text-gray-700 focus:ring focus:ring-blue-300"
            />
          </div>


          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Tag </label>
            <input
              type="text"
              value={tag}
              onChange={(e) => setTag(e.target.value)}
              placeholder="Enter The tag"
              className="w-full px-4 py-2 border rounded-md text-gray-700 focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">DeadLine</label>
            <input
              type="text"
              value={deadline}
              onChange={(e) => setdeadline(e.target.value)}
              placeholder="Enter DeadLine if there is"
              className="w-full px-4 py-2 border rounded-md text-gray-700 focus:ring focus:ring-blue-300"
            />
          </div>
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Blog Cover Image (1000 x 1000 px)
          </label>
          <div className="flex items-center space-x-4 mb-4">
            {(image || selectedImage) && (
              <img
                src={selectedImage || image}
                alt="Preview"
                className="h-20 w-20 rounded-md object-cover border border-gray-300"
              />
            )}
            <input
              type="file"
              onChange={handleFileChangeAndUpload}
              accept="image/*"
              className="file:mr-4 file:py-2 file:px-4 file:rounded-md file:border file:border-gray-300 file:bg-blue-50 file:text-blue-700 file:cursor-pointer hover:file:bg-blue-100"
            />
          </div>
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-1">Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter the blog title"
            className="w-full px-4 py-2 border rounded-md text-gray-700 focus:ring focus:ring-blue-300"
          />
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-1">Category</label>
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="w-full px-4 py-2 border rounded-md text-gray-700 focus:ring focus:ring-blue-300"
          >
            <option value="" disabled>
              Select a category
            </option>
            {categories.map((cat) => (
              <option key={cat.id} value={cat.id}>
                {cat.name}
              </option>
            ))}
          </select>
          <p className="text-sm text-gray-500 mt-1">Selected Category ID: {category || 'None'}</p>
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-1">Content</label>
          <ReactQuill
            value={content}
            onChange={setContent}
            modules={quillModules}
            formats={quillFormats}
            className="bg-white border rounded-md text-gray-700 h-96"
          />
        </div>

        <div className="text-right m-10 mt-12">
          <button
            type="submit"
            className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 focus:ring focus:ring-blue-300"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

const quillModules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' },
    { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image'],
    ['clean']
  ],
};

const quillFormats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
];


export default EditBlogs;