import React, { useState, useEffect } from 'react';
import './editcourse.css';
import '../Edit/EditModuleData.css'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { IoIosRemoveCircle } from "react-icons/io";

const EditModuleData = () => {
    const { id } = useParams();
    const [user, setUser] = useState({
        title: '',
        price: '',
        tag: '',
        discription: ''
    });

    const [courses, setcourses] = useState([]);
    const [teacherss, setTeacherss] = useState([]);
    const [coursess, setcoursess] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const result = await axios.get("https://innovationschool.io:5000/api/module", {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem('token')}`, // Include token if needed
                    },
                });
                setcourses(result.data);
                //consol.log(result.data);
            } catch (error) {
                //consol.log("the error is ", error);
                if (error.response && error.response.status === 401) {

                }
            }
        };
        fetchContacts();
    }, []);

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const result = await axios.get("https://innovationschool.io:5000/api/teacher", {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem('token')}`, // Include token if needed
                    },
                });
                setTeacherss(result.data);
                //consol.log(result.data);
            } catch (error) {
                //consol.log("the error is ", error);
                if (error.response && error.response.status === 401) {

                }
            }
        };
        fetchContacts();
    }, []);



    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const result = await axios.get("https://innovationschool.io:5000/api/courses", {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem('token')}`, // Include token if needed
                    },
                });
                setcoursess(result.data);
                //consol.log(result.data);
            } catch (error) {
                //consol.log("the error is ", error);
                if (error.response && error.response.status === 401) {

                }
            }
        };
        fetchContacts();
    }, []);


    useEffect(() => {
        const fetchCourse = async () => {
            try {
                const response = await axios.get(`https://innovationschool.io:5000/api/moduledata?id=${id}`, {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem('token')}`, // Include token if needed
                    },
                });
                setUser(response.data); // Assuming response.data is an object containing course details
            } catch (error) {
                console.error('Error fetching course:', error);
            }
        };
        fetchCourse();
    }, [id]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setUser(prevUser => ({
            ...prevUser,
            [name]: type === 'checkbox' ? checked : value
        }));
    };




    const handleArrayChange = (e, field, index) => {
        const { value } = e.target;
        setUser((prevData) => ({
            ...prevData,
            [field]: prevData[field].map((item, i) => (i === index ? value : item)),
        }));
    };


    //   Points 1 handalling

    const handleAddPoint = (field) => {
        setUser((prevData) => ({
            ...prevData,
            [field]: [...prevData[field], ''],
        }));
    };

    const handleRemovePoint = (field, index) => {
        setUser((prevData) => ({
            ...prevData,
            [field]: prevData[field].filter((item, i) => i !== index),
        }));
    };


    //   Points 2 handalling

    const handleAddPoint2 = (field) => {
        setUser((prevData) => ({
            ...prevData,
            [field]: [...prevData[field], ''],
        }));
    };

    const handleRemovePoint2 = (field, index) => {
        setUser((prevData) => ({
            ...prevData,
            [field]: prevData[field].filter((item, i) => i !== index),
        }));
    };

    //   .......... requirements

    const handleRequirements = (field) => {
        setUser((prevData) => ({
            ...prevData,
            [field]: [...prevData[field], ''],
        }));
    };

    const handleRemoveRequirements = (field, index) => {
        setUser((prevData) => ({
            ...prevData,
            [field]: prevData[field].filter((item, i) => i !== index),
        }));
    };

    // ............description

    const handleDescription3 = (field) => {
        setUser((prevData) => ({
            ...prevData,
            [field]: [...prevData[field], ''],
        }));
    };

    const handleRemoveDescription3 = (field, index) => {
        setUser((prevData) => ({
            ...prevData,
            [field]: prevData[field].filter((item, i) => i !== index),
        }));
    };


    //..............Lesions

    // const handleObjectArrayChange = (e, field, objIndex, subField, subIndex) => {
    //     const { value } = e.target;
    //     setUser((prevData) => ({
    //         ...prevData,
    //         [field]: prevData[field].map((obj, i) =>
    //             i === objIndex
    //                 ? {
    //                     ...obj,
    //                     [subField]: obj[subField].map((item, j) =>
    //                         j === subIndex ? value : item
    //                     ),
    //                 }
    //                 : obj
    //         ),
    //     }));
    // };

    // const handleAddTopic = () => {
    //     setUser((prevData) => ({
    //         ...prevData,
    //         topic: [...prevData.topic, { name: '', lession: [''] }],
    //     }));
    // };

    // const handleRemoveTopic = (index) => {
    //     setUser((prevData) => ({
    //         ...prevData,
    //         topic: prevData.topic.filter((_, i) => i !== index),
    //     }));
    // };

    // const handleAddLesson = (index) => {
    //     setUser((prevData) => ({
    //         ...prevData,
    //         topic: prevData.topic.map((item, i) =>
    //             i === index ? { ...item, lession: [...item.lession, ''] } : item
    //         ),
    //     }));
    // };

    // const handleRemoveLesson = (topicIndex, lessonIndex) => {
    //     setUser((prevData) => ({
    //         ...prevData,
    //         topic: prevData.topic.map((item, i) =>
    //             i === topicIndex ? { ...item, lession: item.lession.filter((_, j) => j !== lessonIndex) } : item
    //         ),
    //     }));
    // };

    // const handleFileChange = (e, topicIndex, lessonIndex) => {
    //     const file = e.target.files[0];
    //     setUser((prevData) => ({
    //         ...prevData,
    //         topic: prevData.topic.map((item, i) =>
    //             i === topicIndex ? { ...item, lession: item.lession.map((lesson, j) => (j === lessonIndex ? file.name : lesson)) } : item
    //         ),
    //     }));
    // };



    const handleObjectArrayChange = (e, parentKey, parentIndex, childKey, childIndex, fieldKey) => {
        const value = e.target.value;
        setUser((prevData) => ({
            ...prevData,
            [parentKey]: prevData[parentKey]?.map((item, i) =>
                i === parentIndex
                    ? {
                        ...item,
                        [childKey]: item[childKey].map((childItem, j) =>
                            j === childIndex
                                ? { ...childItem, [fieldKey]: value } // This ensures `lessonName` is updated properly
                                : childItem
                        ),
                    }
                    : item
            ),
        }));
    };

    const handleObjectChange = (e, field, objectIndex, subField) => {
        const { value } = e.target;
        setUser((prevData) => {
            const updatedObjectArray = [...prevData[field]];
            const updatedObject = { ...updatedObjectArray[objectIndex] };
            updatedObject[subField] = value;
            updatedObjectArray[objectIndex] = updatedObject;
            return { ...prevData, [field]: updatedObjectArray };
        });
    };

    // const handleFileChange = (e, topicIndex, lessionIndex) => {
    //     const file = e.target.files[0];
    //     setUser((prevData) => {
    //         const updatedTopics = [...prevData.topic];
    //         const updatedlessions = [...updatedTopics[topicIndex].lession];
    //         updatedlessions[lessionIndex] = file.name;
    //         updatedTopics[topicIndex].files[lessionIndex] = file;
    //         return { ...prevData, topic: updatedTopics };
    //     });
    // };

    // const handleFileChange = (e, topicIndex, lessionIndex) => {
    //     const file = e.target.files[0];
    //     setUser((prevData) => ({
    //         ...prevData,
    //         topic: prevData.topic.map((item, i) =>
    //             i === topicIndex ? { ...item, lession: item.lession.map((lession, j) => (j === lessionIndex ? file.name : lession)) } : item
    //         ),
    //     }));
    // };

    // const [filesUrl, setFilesUrl] = useState('');

    // const onUploadfile = async () => {
    //     if (!file) {
    //         alert('Please select a file first.');
    //         return;
    //     }

    //     const formData = new FormData();
    //     formData.append('file', file);

    //     try {
    //         const response = await axios.post('https://innovationschool.io:5000/api/upload', formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //         });
    //         setFilesUrl(response.data.fileUrl);
    //         setUser((prevUser) => ({ ...prevUser, video: response.data.fileUrl }));
    //     } catch (error) {
    //         console.error('Error uploading file:', error);
    //     }
    // };

    // const handleFileChange = async (e, topicIndex, lessionIndex) => {
    //     const file = e.target.files[0];
    //     if (!file) {
    //         alert('Please select a file first.');
    //         return;
    //     }

    //     const formData = new FormData();
    //     formData.append('file', file);

    //     try {
    //         // Upload the file using the API
    //         const response = await axios.post('https://innovationschool.io:5000/api/upload', formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //         });

    //         const fileUrl = response.data.fileUrl;

    //         // Update the state with the uploaded file URL for the corresponding lession
    //         setUser((prevData) => ({
    //             ...prevData,
    //             topic: prevData.topic?.map((item, i) =>
    //                 i === topicIndex
    //                     ? {
    //                           ...item,
    //                           lession: item.lession.map((lession, j) =>
    //                               j === lessionIndex ? { ...lession, fileUrl } : lession
    //                           ),
    //                       }
    //                     : item
    //             ),
    //         }));
    //     } catch (error) {
    //         console.error('Error uploading file:', error);
    //     }
    // };


    const handleFileChange = async (e, topicIndex, lessonIndex, fileType) => {
        const file = e.target.files[0];
        if (!file) {
            alert('Please select a file first.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            // Upload the file using the API
            const response = await axios.post('https://innovationschool.io:5000/api/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            const fileUrl = response.data.fileUrl;

            // Update the state with the uploaded file URL for the corresponding lesson
            setUser((prevData) => ({
                ...prevData,
                topic: prevData.topic.map((topic, i) =>
                    i === topicIndex
                        ? {
                            ...topic,
                            lessons: topic.lessons.map((lesson, j) =>
                                j === lessonIndex ? { ...lesson, [fileType]: fileUrl } : lesson
                            ),
                        }
                        : topic
                ),
            }));
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };



    // const handleAddlession = (index) => {
    //     setUser((prevData) => ({
    //         ...prevData,
    //         topic: prevData.topic?.map((item, i) =>
    //             i === index ? { ...item, lession: [...item.lession, ''] } : item
    //         ),
    //     }));
    // };


    const handleAddlession = (topicIndex) => {
        setUser((prevData) => ({
            ...prevData,
            topic: prevData.topic.map((topic, i) =>
                i === topicIndex
                    ? {
                        ...topic,
                        lessons: [
                            ...topic.lessons,
                            { lessonName: '', pdf: '', video: '' },  // New lesson structure
                        ],
                    }
                    : topic
            ),
        }));
    };



    const handleRemovelession = (topicIndex, lessonIndex) => {
        setUser((prevData) => ({
            ...prevData,
            topic: prevData.topic.map((topic, i) =>
                i === topicIndex
                    ? {
                        ...topic,
                        lessons: topic.lessons.filter((_, j) => j !== lessonIndex),
                    }
                    : topic
            ),
        }));
    };


    // const handleAddTopic = () => {
    //     setUser((prevData) => ({
    //         ...prevData,
    //         topic: [...prevData.topic, { name: '', lession: [''] }],
    //     }));
    // };

    const handleAddTopic = () => {
        setUser((prevData) => ({
            ...prevData,
            topic: [
                ...prevData.topic,
                { name: '', lessons: [{ lessonName: '', pdf: '', video: '' }] },  // New topic with one empty lesson
            ],
        }));
    };


    // const handleRemoveTopic = (topicIndex) => {
    //     setUser((prevData) => {
    //         const updatedTopics = [...prevData.topic];
    //         updatedTopics.splice(topicIndex, 1);
    //         return { ...prevData, topic: updatedTopics };
    //     });
    // };

    const handleRemoveTopic = (topicIndex) => {
        setUser((prevData) => {
            const updatedTopics = [...prevData.topic];
            updatedTopics.splice(topicIndex, 1);
            return { ...prevData, topic: updatedTopics };
        });
    };


    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     const formData = new FormData();
    //     formData.append("image", file);
    //     formData.append("course", course);
    //     formData.append("title", title);
    //     formData.append("videonumber", videonumber);
    //     formData.append("modulenumber", modulenumber);
    //     formData.append("discription", discription);
    //     formData.append("tag", tag);

    //     try {
    //         //consol.log("form data ", formData);
    //         const response = await axios.post(
    //             "https://innovationschool.io:5000/api/moduledata",
    //             formData,
    //             {
    //                 headers: {
    //                     "Content-Type": "multipart/form-data",
    //                 },
    //             }
    //         );
    //         setModule([...module, response.data]);
    //         //consol.log(response.data);
    //     } catch (error) {
    //         //consol.log(error);
    //         console.error("Error uploading the course data", error);
    //     }

    //     setCourse("");
    //     setTitle("");
    //     setVideoNumber("");
    //     setModuleNumber("");
    //     setDiscription("");
    //     setTag("");
    //     setFile(null);

    //     closeModal();
    // };

    const handleDelete = async (courseId) => {
        try {
            const response = await axios.delete(
                `https://innovationschool.io:5000/api/moduledata/${courseId}`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem('token')}`, // Include token if needed
                },
            }
            );

            if (response.status === 200) {
                alert("Course deleted:");
                window.location.reload();
            } else {
                alert("Failed to delete course:");
                window.location.reload();
            }
        } catch (error) {
            //consol.log(error);
            console.error("Error deleting course:", error);
        }
    };


    const handleFileChangeAndUpload = async (e) => {
        const selectedFile = e.target.files[0];
        if (!selectedFile) {
            alert('Please select a file first.');
            return;
        }

        setLoading(true); // Start loading indicator
        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await axios.post('https://innovationschool.io:5000/api/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setUser((prevUser) => ({ ...prevUser, image: response.data.fileUrl })); // Update user with uploaded image URL
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            setLoading(false); // Stop loading indicator
        }
    };



    const handleSubmit = async (e) => {
        e.preventDefault();


        try {
            const response = await axios.put(`https://innovationschool.io:5000/api/moduledata/${id}`, user, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem('token')}`, // Include token if needed
                },
            });
            //consol.log('Updated Course:', response.data);
            alert('Course updated successfully');
        } catch (error) {
            console.error('Error updating course:', error);
            alert('Failed to update course');
        }
    };




    return (
        <div>
            <div className="bg-gray-50 min-h-screen flex flex-col items-center py-8">
                <div className="w-full max-w-[75%] sm:max-w-[80%] md:max-w-[70%] lg:max-w-[60%] bg-white shadow-lg rounded-lg p-6">
                    <h1 className='text-2xl font-bold text-gray-700 mb-6'>Edit Module :{user.title}</h1>
                    <form onSubmit={handleSubmit}>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                Photos (1000 X 1000 px)
                            </label>
                            <div className="flex flex-wrap gap-4 mb-4">

                                {(user.image || selectedImage) && (
                                    <img
                                        src={selectedImage || user.image}
                                        alt="Preview"
                                        className="ml-4 h-20 w-20 object-cover rounded-md border border-gray-300"
                                    />
                                )}

                            </div>
                            <input
                                type="file"
                                onChange={handleFileChangeAndUpload}
                                accept="image/*"
                                className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border file:border-gray-300 file:text-sm file:font-medium file:bg-gray-100 file:hover:bg-gray-200"
                            />

                        </div>

                        <div className="mb-4 mt-4">
                            <label
                                htmlFor="module"
                                className="block text-sm font-medium text-gray-700 mb-2"
                            >
                                Course:
                            </label>
                            <select
                                id="module"
                                name="module"
                                value={user.module}
                                onChange={handleChange}
                                className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                                <option value="" disabled>
                                    Select a course
                                </option>
                                {courses?.map((item, index) => (
                                    <option key={index} value={item.title}>
                                        {item.title}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className='bg-yellow-200 p-4 '>
                        <h2 className="h2 ">For SEO</h2>


                            <div className="mb-4">
                                <label
                                    htmlFor="URL"
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                >
                                    URL
                                </label>
                                <input
                                    id="url"
                                    type="text"
                                    name="url"
                                    value={user.url}
                                    onChange={handleChange}
                                    className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="metatitle"
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                >
                                    Meta Title
                                </label>
                                <input
                                    id="metatitle"
                                    type="text"
                                    name="metatitle"
                                    value={user.metatitle}
                                    onChange={handleChange}
                                    className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="metadesc"
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                >
                                    Meta Discription
                                </label>
                                <input
                                    id="metadesc"
                                    type="text"
                                    name="metadesc"
                                    value={user.metadesc}
                                    onChange={handleChange}
                                    className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="author"
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                >
                                    Author
                                </label>
                                <input
                                    id="author"
                                    type="text"
                                    name="author"
                                    value={user.author}
                                    onChange={handleChange}
                                    className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>

                            <div className="mb-4">
                                <label
                                    htmlFor="keywords"
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                >
                                    Keywords
                                </label>
                                <input
                                    id="keywords"
                                    type="text"
                                    name="keywords"
                                    value={user.keywords}
                                    onChange={handleChange}
                                    className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>

                        </div>


                        <div className="mb-4">
                            <label
                                htmlFor="title"
                                className="block text-sm font-medium text-gray-700 mb-2"
                            >
                                Title
                            </label>
                            <input
                                id="title"
                                type="text"
                                name="title"
                                value={user.title}
                                onChange={handleChange}
                                className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>

                        <div className="mb-4">
                            <label
                                htmlFor="price"
                                className="block text-sm font-medium text-gray-700 mb-2"
                            >
                                Price: ₹
                            </label>
                            <input
                                id="price"
                                type="text"
                                name="price"
                                value={user.price}
                                onChange={handleChange}
                                className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>

                        <div className="mb-4">
                            <label
                                htmlFor="tag"
                                className="block text-sm font-medium text-gray-700 mb-2"
                            >
                                Discounted Price: ₹
                            </label>
                            <input
                                id="priceDis"
                                type="text"
                                name="priceDis"
                                value={user.priceDis}
                                onChange={handleChange}
                                className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>

                        <div className="mb-4">
                            <label
                                htmlFor="course"
                                className="block text-sm font-medium text-gray-700 mb-2"
                            >
                                Category
                            </label>
                            <select
                                id="course"
                                name="course"
                                value={user.course}
                                onChange={handleChange}
                                className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                                <option value="" disabled>
                                    Select a category
                                </option>
                                {coursess?.map((item, index) => (
                                    <option key={index} value={item.title}>
                                        {item.title}
                                    </option>
                                ))}
                            </select>
                        </div>


                        <div className="mb-4">
                            <label
                                htmlFor="language"
                                className="block text-sm font-medium text-gray-700 mb-2"
                            >
                                Language:
                            </label>
                            <input
                                id="language"
                                type="text"
                                name="language"
                                value={user.language}
                                onChange={handleChange}
                                className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>


                        <div className="mb-4 flex items-center space-x-3">
                            <label
                                htmlFor="certificate"
                                className="text-sm font-medium text-gray-700"
                            >
                                Certificate:
                            </label>
                            <input
                                id="certificate"
                                type="checkbox"
                                name="certificate"
                                checked={user.certificate}
                                onChange={handleChange}
                                className="h-5 w-5 border-gray-300 rounded-sm bg-white focus:ring-indigo-500 focus:ring-2 focus:ring-offset-2"
                                role="switch"
                            />
                        </div>


                        <div className="mb-4">
                            <label
                                htmlFor="discription1"
                                className="block text-sm font-medium text-gray-700 mb-2"
                            >
                                Description 1:
                            </label>
                            <textarea
                                id="discription1"
                                name="discription1"
                                value={user.discription1}
                                onChange={handleChange}
                                rows="5"
                                cols="90"
                                className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>


                        <div className="mb-4">
                            <label
                                htmlFor="points1"
                                className="block text-sm font-medium text-gray-700 mb-2"
                            >
                                Points 1:
                            </label>
                            {user.points1?.map((point, index) => (
                                <div key={index} className="flex items-center space-x-2 mb-2">
                                    <input
                                        type="text"
                                        value={point}
                                        onChange={(e) => handleArrayChange(e, 'points1', index)}
                                        className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                    <span
                                        className="cursor-pointer text-red-500 hover:text-red-700"
                                        onClick={() => handleRemovePoint('points1', index)}
                                    >
                                        <IoIosRemoveCircle />
                                    </span>
                                </div>
                            ))}
                            <button
                                type="button"
                                onClick={() => handleAddPoint('points1')}
                                className="mt-2 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Add Point
                            </button>
                        </div>


                        <div className="mb-4">
                            <label
                                htmlFor="points2"
                                className="block text-sm font-medium text-gray-700 mb-2"
                            >
                                Points 2:
                            </label>
                            {user.points2?.map((point, index) => (
                                <div key={index} className="flex items-center space-x-2 mb-2">
                                    <input
                                        type="text"
                                        value={point}
                                        onChange={(e) => handleArrayChange(e, 'points2', index)}
                                        className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                    <span
                                        className="cursor-pointer text-red-500 hover:text-red-700"
                                        onClick={() => handleRemovePoint2('points2', index)}
                                    >
                                        <IoIosRemoveCircle />
                                    </span>
                                </div>
                            ))}
                            <button
                                type="button"
                                onClick={() => handleAddPoint2('points2')}
                                className="mt-2 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Add Point
                            </button>
                        </div>


                        <div className="mb-4">
                            <label
                                htmlFor="discription2"
                                className="block text-sm font-medium text-gray-700 mb-2"
                            >
                                Description 2:
                            </label>
                            <textarea
                                id="discription2"
                                name="discription2"
                                value={user.discription2}
                                onChange={handleChange}
                                cols="90"
                                rows="5"
                                className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>


                        <div className="mb-4">
                            <label
                                htmlFor="requirements"
                                className="block text-sm font-medium text-gray-700 mb-2"
                            >
                                Requirements:
                            </label>
                            {user.requirements?.map((point, index) => (
                                <div key={index} className="flex items-center space-x-2 mb-2">
                                    <input
                                        type="text"
                                        value={point}
                                        onChange={(e) => handleArrayChange(e, 'requirements', index)}
                                        className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                    <span
                                        className="cursor-pointer text-red-500 hover:text-red-700"
                                        onClick={() => handleRemoveRequirements('requirements', index)}
                                    >
                                        <IoIosRemoveCircle />
                                    </span>
                                </div>
                            ))}
                            <button
                                type="button"
                                onClick={() => handleRequirements('requirements')}
                                className="mt-2 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Add Point
                            </button>
                        </div>


                        <div className="mb-4">
                            <label
                                htmlFor="rating"
                                className="block text-sm font-medium text-gray-700 mb-2"
                            >
                                Rating:
                            </label>
                            <input
                                id="rating"
                                type="text"
                                name="rating"
                                value={user.rating}
                                onChange={handleChange}
                                className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>


                        <div className="mb-4">
                            <label
                                htmlFor="description3"
                                className="block text-sm font-medium text-gray-700 mb-2"
                            >
                                Description:
                            </label>
                            {user.description3?.map((point, index) => (
                                <div key={index} className="flex items-center space-x-2 mb-2">
                                    <input
                                        type="text"
                                        value={point}
                                        onChange={(e) => handleArrayChange(e, 'description3', index)}
                                        className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                    <span
                                        className="cursor-pointer text-red-500 hover:text-red-700"
                                        onClick={() => handleRemoveDescription3('description3', index)}
                                    >
                                        <IoIosRemoveCircle />
                                    </span>
                                </div>
                            ))}
                            <button
                                type="button"
                                onClick={() => handleDescription3('description3')}
                                className="mt-2 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Add Point
                            </button>
                        </div>


                        <div className="mb-4">
                            <label
                                htmlFor="teacher"
                                className="block text-sm font-medium text-gray-700 mb-2"
                            >
                                Teacher:
                            </label>
                            <select
                                name="teacher"
                                value={user.teacher}
                                onChange={handleChange}
                                id="teacher"
                                className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                                <option value="" disabled>Select a teacher</option>
                                {teacherss?.map((item, index) => (
                                    <option key={index} value={item.title}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>


                        <div className="space-y-4">
                            <div>
                                <label
                                    htmlFor="lecture"
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                >
                                    Number of Lectures:
                                </label>
                                <input
                                    type="text"
                                    name="lecture"
                                    value={user.lecture}
                                    onChange={handleChange}
                                    id="lecture"
                                    className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="quizzes"
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                >
                                    Number of Quizzes:
                                </label>
                                <input
                                    type="text"
                                    name="quizzes"
                                    value={user.quizzes}
                                    onChange={handleChange}
                                    id="quizzes"
                                    className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="skillLevel"
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                >
                                    Required Skill Level:
                                </label>
                                <input
                                    type="text"
                                    name="skillLevel"
                                    value={user.skillLevel}
                                    onChange={handleChange}
                                    id="skillLevel"
                                    className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>


                        <div className="bg-[#ffb250] p-6 rounded-md m-4 space-y-4">
                            <label className="block text-lg font-semibold">Topics:</label>
                            {user.topic?.map((topic, topicIndex) => (
                                <div key={topicIndex} className="bg-white p-4 rounded-md shadow-sm">
                                    <div>
                                        <label className="block text-sm font-medium">Topic Name:</label>
                                        <input
                                            type="text"
                                            value={topic.name}
                                            onChange={(e) => handleObjectChange(e, 'topic', topicIndex, 'name')}
                                            className="block w-full mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                        />
                                    </div>

                                    <div className="mt-4">
                                        <label className="block text-sm font-medium">Lessons:</label>
                                        {topic.lessons.map((lesson, lessonIndex) => (
                                            <div key={lessonIndex} className="bg-[#d1a7b8] p-3 rounded-md mt-2 space-y-3">
                                                <div>
                                                    <label className="block text-sm font-medium">Lesson Name:</label>
                                                    <input
                                                        type="text"
                                                        value={lesson.lessonName}
                                                        onChange={(e) => handleObjectArrayChange(e, 'topic', topicIndex, 'lessons', lessonIndex, 'lessonName')}
                                                        className="block w-full mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                                    />
                                                </div>

                                                <div className="mt-2">
                                                    <label className="block text-sm font-medium">PDF:</label>
                                                    <input
                                                        type="file"
                                                        onChange={(e) => handleFileChange(e, topicIndex, lessonIndex, 'pdf')}
                                                        className="block mt-1 p-2 border border-gray-300 rounded-md"
                                                    />
                                                    {lesson.pdf && <p className="mt-1 text-sm text-blue-600"><a href={lesson.pdf} target="_blank" rel="noopener noreferrer">View PDF</a></p>}
                                                </div>

                                                <div className="mt-2">
                                                    <label className="block text-sm font-medium">Video:</label>
                                                    <input
                                                        type="file"
                                                        onChange={(e) => handleFileChange(e, topicIndex, lessonIndex, 'video')}
                                                        className="block mt-1 p-2 border border-gray-300 rounded-md"
                                                    />
                                                    {lesson.video && <p className="mt-1 text-sm text-blue-600"><a href={lesson.video} target="_blank" rel="noopener noreferrer">Watch Video</a></p>}
                                                </div>

                                                <button
                                                    className="bg-red-500 text-white mt-3 p-2 rounded-sm"
                                                    type="button"
                                                    onClick={() => handleRemovelession(topicIndex, lessonIndex)}
                                                >
                                                    Remove Lesson
                                                </button>
                                            </div>
                                        ))}

                                        <button
                                            className="bg-blue-600 text-white mt-3 p-2 rounded-sm"
                                            type="button"
                                            onClick={() => handleAddlession(topicIndex)}
                                        >
                                            Add Lesson
                                        </button>
                                    </div>

                                    <button
                                        className="bg-red-600 text-white mt-4 p-2 rounded-sm"
                                        type="button"
                                        onClick={() => handleRemoveTopic(topicIndex)}
                                    >
                                        Remove Topic
                                    </button>
                                </div>
                            ))}

                            <button
                                className="bg-green-600 text-white mt-4 p-2 rounded-sm"
                                type="button"
                                onClick={handleAddTopic}
                            >
                                Add Topic
                            </button>
                        </div>

                        <input
                            className="bg-blue-600 text-white p-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                            type="submit"
                            value="Update"
                        />

                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditModuleData;
