import React from 'react'
import './dashboardhome.css'

const DashboradHome = () => {
  const isAuthenticated = !!localStorage.getItem('token');
  return (
    <div className='p-2'> 
      <div className="dashboard_home">
        <h2 className='h2 text-white'>Dashboard</h2>
        <p className='text-white'> Whole data about your business here</p>
         
      </div>
    </div>
  )
}

export default DashboradHome
