import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx'; // For exporting Excel file

const RgisteredPeople = () => {
    const [webinarpeople, setwebinarpeople] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [filter, setFilter] = useState('');
    const [selectedWebinar, setSelectedWebinar] = useState('');

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const result = await axios.get('https://innovationschool.io:5000/api/webinarpeople', {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                setwebinarpeople(result.data);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    // handle unauthorized access
                }
            }
        };
        fetchContacts();
    }, []);

    // Filtered data based on webinar and other filter
    const filteredData = webinarpeople.filter((item) => {
        const webinarMatch = item.webinar.toLowerCase().includes(filter.toLowerCase());
        const webinarSelectedMatch = selectedWebinar ? item.webinar === selectedWebinar : true;
        return webinarMatch && webinarSelectedMatch;
    });

    // Function to prepare data for CSV export
    const handleExport = (format) => {
        // Map filtered data to a format that can be exported
        const exportData = filteredData.map((item) => ({
            Name: item.name ?? 'N/A',
            Email: item.email ?? 'N/A',
            Country: item.country ?? 'N/A',
            Mobile: item.mobile ?? 'N/A',
            Profession: item.profession ?? 'N/A',
            Webinar: typeof item.webinar === 'string' ? item.webinar : JSON.stringify(item.webinar ?? 'N/A'),
        }));

        if (format === 'csv' || format === 'excel') {
            const ws = XLSX.utils.json_to_sheet(exportData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Webinar Participants');
            const fileExtension = format === 'csv' ? '.csv' : '.xlsx';
            XLSX.writeFile(wb, `Webinar_Participants${fileExtension}`);
        } else if (format === 'txt') {
            const textContent = exportData
                .map((row) => Object.values(row).join('\t'))
                .join('\n');
            const blob = new Blob([textContent], { type: 'text/plain;charset=utf-8' });
            saveAs(blob, 'Webinar_Participants.txt');
        }
    };

    // Paginate data
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

    const totalPages = Math.ceil(filteredData.length / rowsPerPage);

    // Handle page change
    const changePage = (direction) => {
        setCurrentPage((prevPage) =>
            direction === 'next' ? prevPage + 1 : prevPage - 1
        );
    };

    return (
        <div>
            <div className="p-6 bg-[#992959] rounded-lg shadow-md">
                <div className="mb-4 flex flex-col sm:flex-row justify-between items-center">
                    <input
                        type="text"
                        placeholder="Filter by Webinar"
                        className="p-2 border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#992959] mb-4 sm:mb-0"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                    />

                    {/* Webinar Filter Dropdown */}
                    <select
                        className="p-2 border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#992959] mb-4 sm:mb-0"
                        value={selectedWebinar}
                        onChange={(e) => setSelectedWebinar(e.target.value)}
                    >
                        <option value="">All Webinars</option>
                        {/* Get unique webinar values */}
                        {Array.from(new Set(webinarpeople.map((item) => item.webinar))).map((webinar, index) => (
                            <option key={index} value={webinar}>
                                {webinar}
                            </option>
                        ))}
                    </select>


                    {/* Records per page Dropdown */}
                    <select
                        className="p-2 border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#992959] mb-4 sm:mb-0"
                        value={rowsPerPage}
                        onChange={(e) => setRowsPerPage(Number(e.target.value))}
                    >
                        <option value={5}>5 Records</option>
                        <option value={10}>10 Records</option>
                        <option value={15}>15 Records</option>
                        <option value={20}>20 Records</option>
                    </select>

                    {/* Export CSV Button */}
                    <div className="mt-4 flex justify-end">
                        <button
                            onClick={() => handleExport('csv')}
                            className="px-4 py-2 bg-[#992959] text-white rounded-md shadow hover:bg-[#7e224d] transition mr-2"
                        >
                            Export as CSV
                        </button>
                        <button
                            onClick={() => handleExport('excel')}
                            className="px-4 py-2 bg-[#992959] text-white rounded-md shadow hover:bg-[#7e224d] transition mr-2"
                        >
                            Export as Excel
                        </button>
                        <button
                            onClick={() => handleExport('txt')}
                            className="px-4 py-2 bg-[#992959] text-white rounded-md shadow hover:bg-[#7e224d] transition"
                        >
                            Export as TXT
                        </button>
                    </div>

                </div>


                <div className="overflow-x-auto">
                    <table className="w-full table-auto border-collapse border border-gray-200">
                        <thead className="bg-[#992959] text-white">
                            <tr>
                                <th className="px-4 py-2 text-left">Name</th>
                                <th className="px-4 py-2 text-left">Email</th>
                                <th className="px-4 py-2 text-left">Country</th>
                                <th className="px-4 py-2 text-left">Mobile</th>
                                <th className="px-4 py-2 text-left">Profession</th>
                                <th className="px-4 py-2 text-left">Webinar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentRows.map((item, index) => (
                                <tr key={index} className="odd:bg-white even:bg-gray-100 hover:bg-gray-200">
                                    <td className="px-4 py-2">{item.name ?? 'N/A'}</td>
                                    <td className="px-4 py-2">{item.email ?? 'N/A'}</td>
                                    <td className="px-4 py-2">{item.country ?? 'N/A'}</td>
                                    <td className="px-4 py-2">{item.mobile ?? 'N/A'}</td>
                                    <td className="px-4 py-2">{item.profession ?? 'N/A'}</td>
                                    <td className="px-4 py-2">
                                        {typeof item.webinar === 'string'
                                            ? item.webinar
                                            : JSON.stringify(item.webinar ?? 'N/A')}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>




                <div className="mt-4 flex justify-between items-center">
                    <button
                        className={`px-4 py-2 rounded-md shadow ${currentPage === 1
                            ? 'bg-gray-300 cursor-not-allowed'
                            : 'bg-[#992959] text-white hover:bg-[#7e224d] transition'
                            }`}
                        onClick={() => changePage('prev')}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    <span className='text-white size-xl'>
                        Page {currentPage} of {totalPages}
                    </span>
                    <button
                        className={`px-4 py-2 rounded-md shadow ${currentPage === totalPages || filteredData.length === 0
                            ? 'bg-gray-300 cursor-not-allowed'
                            : 'bg-[#992959] text-white hover:bg-[#7e224d] transition'
                            }`}
                        onClick={() => changePage('next')}
                        disabled={currentPage === totalPages || filteredData.length === 0}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RgisteredPeople;
