// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReactModal__Content  {
    /* border: 1px solid red !important; */
    height: 80%;
}`, "",{"version":3,"sources":["webpack://./src/components/modules/modules.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,WAAW;AACf","sourcesContent":[".ReactModal__Content  {\n    /* border: 1px solid red !important; */\n    height: 80%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
