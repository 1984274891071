import React, { useState, useEffect } from 'react';
import './editcourse.css'
import { useParams } from 'react-router-dom';
import axios from 'axios';


const Editquizz = () => {
    const { id } = useParams();

    const [user, setUser] = useState({});
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        const fetchQuiz = async () => {
            try {
                const result = await axios.get(`https://innovationschool.io:5000/api/quizzes/${id}`, {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                console.log(result)
                setUser(result.data[0].module);  // Set the module (course) data
                setQuestions(result.data[0].questions);  // Set the quiz questions data
            } catch (error) {
                console.error("Error fetching quiz data:", error);
                if (error.response && error.response.status === 401) {
                    // Handle unauthorized access
                }
            }
        };
        fetchQuiz();
    }, [id]);

    const handleChange = (e) => {
        setUser(e.target.value);  // Update selected module (course)
    };

    const handleQuestionChange = (qIndex, e) => {
        const newQuestions = [...questions];
        newQuestions[qIndex].question = e.target.value;  // Update question text
        setQuestions(newQuestions);
    };

    const handleOptionChange = (qIndex, oIndex, e) => {
        const newQuestions = [...questions];
        newQuestions[qIndex].options[oIndex] = e.target.value;  // Update option text
        setQuestions(newQuestions);
    };

    const handleAnswerChange = (qIndex, e) => {
        const newQuestions = [...questions];
        newQuestions[qIndex].answer = e.target.value;  // Update correct answer
        setQuestions(newQuestions);
    };

    const addQuestion = () => {
        const newQuestion = {
            id: Date.now().toString(),
            question: '',
            options: ['', '', '', ''],
            answer: '',
        };
        setQuestions([...questions, newQuestion]);  // Add new question
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const result = await axios.put(
                `https://innovationschool.io:5000/api/quizzes/${id}`,
                { module: user, questions },
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );
            if (result.status === 200) {
                alert('Quiz updated successfully!');
            } else {
                alert('Failed to update quiz.');
            }
        } catch (error) {
            console.error("Error updating quiz:", error);
        }
    };


    
    return (
        <>
            <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-lg space-y-6 max-w-4xl mx-auto">
    <div className="flex flex-col">
        <label className="text-lg font-semibold text-gray-700">Module</label>
        <input type="text" disabled value={user}
            className="mt-1 px-4 py-2 border border-gray-300 rounded-lg bg-gray-100 text-gray-700 cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
    </div>

    {questions?.map((q, qIndex) => (
        <div key={q.id} className="question-card bg-gray-50 p-5 rounded-lg shadow-md transform transition-all duration-300 hover:scale-105">
            <label className="block">
                <h5 className="text-lg font-semibold text-gray-800">Question {qIndex + 1}:</h5>
                <input
                    className="question_inp mt-2 w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    type="text"
                    value={q.question}
                    onChange={(e) => handleQuestionChange(qIndex, e)}
                    placeholder="Enter your question"
                />
            </label>

            <div className="options mt-4 space-y-2">
                {q.options.map((option, oIndex) => (
                    <label key={oIndex} className="block">
                        <p className="text-gray-700">Option {oIndex + 1}:</p>
                        <input
                            type="text"
                            value={option}
                            onChange={(e) => handleOptionChange(qIndex, oIndex, e)}
                            className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder={`Enter option ${oIndex + 1}`}
                        />
                    </label>
                ))}
            </div>

            <label className="correct_ans block mt-4">
                <p className="text-gray-700 font-medium">Correct Answer:</p>
                <input
                    type="text"
                    value={q.answer}
                    onChange={(e) => handleAnswerChange(qIndex, e)}
                    className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                    placeholder="Enter correct answer"
                />
            </label>
        </div>
    ))}

    <div className="flex items-center space-x-4">
        <button type="button" onClick={addQuestion}
            className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300">
            Add Question
        </button>

        <button type="submit"
            className="px-6 py-2 bg-green-500 text-white font-semibold rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-300">
            Update Quiz
        </button>
    </div>
</form>


        </>
    )

}


export default Editquizz;