import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { IoIosCloseCircleOutline } from "react-icons/io";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import Modal2 from "../Modal2";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const quillModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

const quillFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");


  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const [keywords, setkeywords] = useState('');
  const [url, setUrl] = useState('');
  const [author, setAuthor] = useState('innovation school');
  const [metatitle, setMetaTitle] = useState('');
  const [metadesc, setMetadis] = useState('');
  const [category, setCategory] = useState('');
  const [categoryes, setCategoryes] = useState([]);

  const [tag, setTag] = useState('');
  const [deadline, setdeadline] = useState('')


  const fetchCategories = async () => {
    try {
      const response = await axios.get('/api/categories');
      setCategoryes(response.data.data);
    } catch (error) {
      console.error('Error fetching categories:', error.response?.data?.message || error.message);
    }
  };

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        // Start by setting loading to true

        console.log(localStorage.getItem('token'))

        const response = await axios.get("https://innovationschool.io:5000/api/blogs", {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`, // Include token if needed
          },
        });

        // Set the blogs state with the response data
        setBlogs(response.data);
        fetchCategories();
      } catch (error) {
        // Handle any errors that occur during the fetch
        console.error("There was an error fetching the blogs!", error);
        // Set error state
      } finally {
        // Always set loading to false at the end of the operation

      }
    };

    fetchBlogs();
  }, []);

  const handleEdit = (_id) => {
    navigate(`/blogs/${_id}`);
  };

  let subtitle;

  const openModal = () => {
    setIsOpen(true);
  };

  const handleFileChangeimg = (e) => {
    setFile(e.target.files[0]);
  };

  const afterOpenModal = () => {
    subtitle.style.color = "#f00";
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("keywords", keywords);
    formData.append("url", url);
    formData.append("author", author);
    formData.append("metatitle", metatitle);
    formData.append("metadesc", metadesc);
    formData.append("category", category);
    formData.append("tag", tag);
    formData.append("deadline", deadline);

    if (file) {
      formData.append("cardImage", file);
    }

    try {
      const response = await axios.post(
        "https://innovationschool.io:5000/api/blogs",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      ); // Replace with your API endpoint
      //consol.log("Response:", response.data);
      setBlogs([...blogs, response.data]); // Update the list of blogs
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    closeModal();
  };

  const handleDelete = async (courseId) => {
    try {
      const response = await axios.delete(
        `https://innovationschool.io:5000/api/blogs/${courseId}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`, // Include token if needed
        },
      }
      );

      if (response.status === 200) {
        alert("Course deleted:");
        setBlogs(blogs.filter((blog) => blog._id !== courseId)); // Update the list of blogs
      } else {
        alert("Blog to delete course:");
      }
    } catch (error) {
      //consol.log(error);
      console.error("Error deleting Blog:", error);
    }
  };


  const handleAddCategory = (name) => {
    setCategories([...categories, { id: Date.now(), name }]);
  };

  const handleEditCategory = (id, name) => {
    setCategories(
      categories.map((cat) => (cat.id === id ? { ...cat, name } : cat))
    );
  };

  const handleDeleteCategory = (id) => {
    setCategories(categories.filter((cat) => cat.id !== id));
  };

  return (
    <div>
      <div className="courses ml-2 rounded-sm">
        <div className="courses_page_header">
          <div className="courses_page_head">
            <h2 className="h2 text-stone-50 ml-3">Available blogs</h2>
          </div>
          <div className="courses_page_button">
            <input type="button" value="Add New Blog" onClick={openModal} />


            <button
              onClick={() => setIsModalOpen(true)}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Categories
            </button>
          </div>

        </div>

        <Modal2
          categories={categories}
          onAddCategory={handleAddCategory}
          onEditCategory={handleEditCategory}
          onDeleteCategory={handleDeleteCategory}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="modal_wrapper mt-2">
            <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
              Fill the details!
            </h2>
            <button className="close_btn" onClick={closeModal}>
              <IoIosCloseCircleOutline />
            </button>
            <form className="modal_form bg-white shadow-lg rounded-lg p-6" onSubmit={handleSubmit}>
              <div className="mb-6">
                <h2 className="text-2xl font-bold text-gray-800 mb-6">SEO Details</h2>

                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">Meta Title</label>
                    <input
                      type="text"
                      value={metatitle}
                      onChange={(e) => setMetaTitle(e.target.value)}
                      placeholder="Enter your meta title"
                      className="w-full px-4 py-2 border rounded-md text-gray-700 focus:ring focus:ring-blue-300 focus:outline-none"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">Meta Keywords</label>
                    <input
                      type="text"
                      value={keywords}
                      onChange={(e) => setkeywords(e.target.value)}
                      placeholder="Enter your meta keywords"
                      className="w-full px-4 py-2 border rounded-md text-gray-700 focus:ring focus:ring-blue-300 focus:outline-none"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">Meta Description</label>
                    <textarea
                      value={metadesc}
                      onChange={(e) => setMetadis(e.target.value)}
                      placeholder="Enter your meta description"
                      className="w-full px-4 py-2 border rounded-md text-gray-700 focus:ring focus:ring-blue-300 focus:outline-none resize-none"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">Author</label>
                    <input
                      type="text"
                      value={author}
                      onChange={(e) => setAuthor(e.target.value)}
                      placeholder="Enter the author name"
                      className="w-full px-4 py-2 border rounded-md text-gray-700 focus:ring focus:ring-blue-300 focus:outline-none"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">Custom URL</label>
                    <input
                      type="text"
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                      placeholder="Enter your custom URL"
                      className="w-full px-4 py-2 border rounded-md text-gray-700 focus:ring focus:ring-blue-300 focus:outline-none"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">Tag</label>
                    <input
                      type="text"
                      value={tag}
                      onChange={(e) => setTag(e.target.value)}
                      placeholder="Enter the tag"
                      className="w-full px-4 py-2 border rounded-md text-gray-700 focus:ring focus:ring-blue-300 focus:outline-none"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">Deadline</label>
                    <input
                      type="text"
                      value={deadline}
                      onChange={(e) => setdeadline(e.target.value)}
                      placeholder="Enter the deadline (if any)"
                      className="w-full px-4 py-2 border rounded-md text-gray-700 focus:ring focus:ring-blue-300 focus:outline-none"
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Upload Image</label>
                  <input
                    type="file"
                    id="cardImage"
                    name="cardImage"
                    onChange={handleFileChangeimg}
                    className="block w-full text-sm text-gray-700 border border-gray-300 rounded-md focus:ring focus:ring-blue-300 focus:outline-none file:mr-4 file:py-2 file:px-4 file:rounded file:border-none file:bg-blue-50 file:text-blue-700"
                  />
                </div>

                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Category</label>
                  <select
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md text-gray-700 focus:ring focus:ring-blue-300 focus:outline-none"
                  >
                    <option value="" disabled>
                      Select a category
                    </option>
                    {categoryes.map((cat) => (
                      <option key={cat.id} value={cat.id}>
                        {cat.name}
                      </option>
                    ))}
                  </select>
                  <p className="text-sm text-gray-500 mt-1">Selected Category ID: {category || 'None'}</p>
                </div>

                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Title</label>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Enter the title"
                    className="w-full px-4 py-2 border rounded-md text-gray-700 focus:ring focus:ring-blue-300 focus:outline-none"
                  />
                </div>

                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Content</label>
                  <ReactQuill
                    value={content}
                    onChange={setContent}
                    modules={quillModules}
                    formats={quillFormats}
                    className="bg-white border rounded-md focus:ring focus:ring-blue-300 focus:outline-none"
                  />
                </div>

                <button
                  type="submit"
                  className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300"
                >
                  Save
                </button>
              </div>
            </form>

          </div>
        </Modal>

        <div className="table mt-4">
          <table className="rounded-md">
            <div className="table_wrapper">
              <tr className="heading_row">
                <th>IMAGE</th>
                <th>TITLE</th>
                <th>DATE</th>
                <th>EDIT</th>
                <th>DELETE</th>
              </tr>
              {blogs.map((course) => (
                <tr key={course._id}>
                  <td>
                    <img
                      src={course.cardImage}
                      alt={course.name}
                      height={100}
                      width={100}
                    />
                  </td>
                  <td>{course.title}</td>
                  <td>
                    {course.date}&nbsp; {course.year}
                  </td>
                  <td className="edit_btn">
                    <input
                      type="button"
                      value="Edit Info"
                      onClick={() => handleEdit(course._id)}
                    />
                  </td>
                  <td className="delete_btn">
                    <input
                      type="button"
                      value="Delete"
                      onClick={() => handleDelete(course._id)}
                    />
                  </td>
                </tr>
              ))}
            </div>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Blogs;
