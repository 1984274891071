import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';

import Modal from 'react-modal';
import { IoIosCloseCircleOutline } from "react-icons/io";

import CourseIMG from '../../assets/courses.jpg'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const Webinar = () => {
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [webinar, setWebinar] = useState([]);


  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const result = await axios.get('https://innovationschool.io:5000/api/webinar', {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setWebinar(result.data);
        //consol.log(result.data);
      } catch (error) {
        //consol.log("the erro is ", error);
        if (error.response && error.response.status === 401) {
          navigate('/');
        }
      }
    };
    fetchContacts();

  }, []);

  const handleEdit = (_id) => {
    navigate(`/webinar/${_id}`);
  };

  const registeredPeople = () => {
    navigate('/rgisteredPeople')
  }

  let subtitle;

  const openModal = () => {
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    subtitle.style.color = "#f00";
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [imageUrl, setimageUrl] = useState('')
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    ispaid: 'fasle',
    link: '',
    img: '',
    date: '',
    year: '',
    time: '',
    price: '',
    heading1: '',
    pointsOne: [''],
    speakers: [
      {
        name: '',
        image: '', // Initialize as array of strings
        profession: '',
        description: ''
      },
    ],
    category: '',
    duration: '',
  });




  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleArrayChange = (index, value, field) => {
    const updatedArray = [...formData[field]];
    updatedArray[index] = value;
    setFormData({ ...formData, [field]: updatedArray });
  };

  const handleSpeakerDetailChange = (speakerIndex, detailIndex, value) => {
    const updatedSpeakers = [...formData.speakers];
    updatedSpeakers[speakerIndex].details[detailIndex] = value;
    setFormData({ ...formData, speakers: updatedSpeakers });
  };

  const addPoint = (field) => {
    setFormData({ ...formData, [field]: [...formData[field], ''] });
  };

  const removePoint = (index, field) => {
    const updatedArray = formData[field].filter((_, i) => i !== index);
    setFormData({ ...formData, [field]: updatedArray });
  };

  const addSpeaker = () => {
    setFormData({
      ...formData,
      speakers: [...formData.speakers, { name: '', image: '', details: [''] }],
    });
  };

  const removeSpeaker = (index) => {
    const updatedSpeakers = formData.speakers.filter((_, i) => i !== index);
    setFormData({ ...formData, speakers: updatedSpeakers });
  };

  const addSpeakerDetail = (speakerIndex) => {
    const updatedSpeakers = [...formData.speakers];
    updatedSpeakers[speakerIndex].details.push('');
    setFormData({ ...formData, speakers: updatedSpeakers });
  };

  const removeSpeakerDetail = (speakerIndex, detailIndex) => {
    const updatedSpeakers = [...formData.speakers];
    updatedSpeakers[speakerIndex].details = updatedSpeakers[speakerIndex].details.filter(
      (_, i) => i !== detailIndex
    );
    setFormData({ ...formData, speakers: updatedSpeakers });
  };



  const handleFileChange = async (e, speakerIndex) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const formDataFile = new FormData();
      formDataFile.append('file', file);

      const response = await fetch('https://innovationschool.io:5000/api/upload', {
        method: 'POST',
        body: formDataFile,
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`, // Include token if needed
        },
      });

      if (!response.ok) throw new Error('Upload failed');

      const result = await response.json();
      const imageUrl = result.fileUrl;

      const updatedSpeakers = [...formData.speakers];
      updatedSpeakers[speakerIndex].image = imageUrl;
      setFormData({ ...formData, speakers: updatedSpeakers });
    } catch (error) {
      console.error('Upload error:', error);
    }
  };


  const [fileUrl, setFileUrl] = useState('');
  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const onUpload = async () => {
    if (!file) {
      alert('Please select a file first.');
      return;
    }

    const formDatas = new FormData();
    formDatas.append('file', file);

    try {
      const response = await axios.post('https://innovationschool.io:5000/api/upload', formDatas, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setFileUrl(response.data.fileUrl);
      // const updatedSpeakers = [...formData.img];
      // updatedSpeakers.img = response.data.fileUrl;
      // setFormData({ ...formData, img: updatedSpeakers });

      setFormData((formData) => ({ ...formData, img: response.data.fileUrl }));
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };







  const handleSubmit = async (e) => {
    e.preventDefault();
    const transformedData = {
      ...formData,
      pointsOne: formData.pointsOne,
      pointsTwo: formData.pointsTwo,
      speakers: formData.speakers.map(speaker => ({
        name: speaker.name,
        image: speaker.image,
        profession: speaker.profession, // No transformation needed here as details are already strings
        description: speaker.description,
      }))
    };
    try {
      const response = await axios.post('https://innovationschool.io:5000/api/webinar', transformedData, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }); // Replace with your API endpoint
      //consol.log('Response:', response.data);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
    closeModal();
  };


  const handleDelete = async (courseId) => {
    try {
      const response = await axios.delete(`https://innovationschool.io:5000/api/webinar/${courseId}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.status === 200) {
        alert("Course deleted:");
        window.location.reload();
      } else {
        alert("Failed to delete course:");
        window.location.reload();
      }
    } catch (error) {
      //consol.log(error);
      console.error("Error deleting course:", error);
    }
  };


  return (
    <div >
      <div className="courses ml-2 rounded-sm">
        <div className="courses_page_header">
          <div className="courses_page_head">
            <h2 className='h2 text-stone-50 ml-3'>Available Webinars</h2>
            <button
              onClick={registeredPeople}
              className="px-6 py-3 bg-white text-[#992959] font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 transition-transform transform hover:scale-105"
            >
              Webinar
            </button>

          </div>
          <div className="courses_page_button">
            <input type="button" value="Add Webinar" onClick={openModal} />
          </div>

        </div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="modal_wrapper mt-2">
            <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Fill the details!</h2>
            <button className='close_btn' onClick={closeModal}>
              <IoIosCloseCircleOutline />
            </button>
            <form className="max-w-3xl mx-auto p-4" onSubmit={handleSubmit}>
              <h2 className="text-2xl font-bold mb-4">Create Webinar</h2>

              {/* Basic Information */}

              <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Paid Or Not </label>
                <select
                  name="ispaid"
                  className="w-full border p-2 mb-4"
                  value={formData.ispaid}
                  onChange={handleInputChange}
                  required
                >
                  <option value="" selected disabled>Select payment status</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>

              <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Link for Webinar </label>
                <input
                  type="text"
                  name="link"
                  placeholder="Link for Webinar"
                  className="w-full border p-2 mb-4"
                  value={formData.link}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Title </label>
                <input
                  type="text"
                  name="title"
                  placeholder="Title"
                  className="w-full border p-2 mb-4"
                  value={formData.title}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Card Photo</label>
                <input className="mb-3" type="file" onChange={onFileChange} />
                <span className="bg-blue-400 p-2 m-3 rounded-md cursor-pointer" onClick={onUpload}>Upload</span>
                {formData.img && <img src={formData.img} alt={`Cover img`} height="100px" width="100px" />}
              </div>
              <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Date </label>
                <input
                  type="datetime"
                  name="date"
                  placeholder="Date"
                  className="w-full border p-2 mb-4"
                  value={formData.date}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Year </label>
                <input
                  type="text"
                  name="year"
                  placeholder="Year"
                  className="w-full border p-2 mb-4"
                  value={formData.year}
                  onChange={handleInputChange}
                />
              </div>


              <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Timing </label>
                <input
                  type="text"
                  name="time"
                  placeholder="Time"
                  className="w-full border p-2 mb-4"
                  value={formData.time}
                  onChange={handleInputChange}
                  required
                /></div>

              <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Duration in minutes </label>
                <input
                  type="text"
                  name="duration"
                  placeholder="Duration"
                  className="w-full border p-2 mb-4"
                  value={formData.duration}
                  onChange={handleInputChange}
                  required
                /></div>

              <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Category </label>
                <input
                  type="text"
                  name="category"
                  placeholder="Category"
                  className="w-full border p-2 mb-4"
                  value={formData.category}
                  onChange={handleInputChange}
                  required
                /></div>


              <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Price </label>
                <input
                  type="text"
                  name="price"
                  placeholder="Price"
                  className="w-full border p-2 mb-4"
                  value={formData.price}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>First heading</label>
                <textarea
                  name="heading1"
                  placeholder="Heading 1"
                  className="w-full border p-2 mb-4"
                  value={formData.heading1}
                  onChange={handleInputChange}
                  required
                />
              </div>


              {/* <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Second heading </label>
                <input
                  type="text"
                  name="heading2"
                  placeholder="Heading 2"
                  className="w-full border p-2 mb-4"
                  value={formData.heading2}
                  onChange={handleInputChange}
                  required
                />
              </div> */}



              {/* <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Heading about wbinaar  </label>

                <input
                  type="text"
                  name="heading3"
                  placeholder="Heading 3"
                  className="w-full border p-2 mb-4"
                  value={formData.heading3}
                  onChange={handleInputChange}
                  required
                />
              </div> */}



              {/* <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Heading about wbinaar topic if required </label>
                <input
                  type="text"
                  name="heading4"
                  placeholder="Heading about wbinaar topic if required"
                  className="w-full border p-2 mb-4"
                  value={formData.heading4}
                  onChange={handleInputChange}
                  required
                />
              </div> */}

              {/* Points Two */}
              {/* <div className="mb-4">
                <label className="block mb-2 font-bold">Points Two</label>
                {formData.pointsTwo.map((point, index) => (
                  <div key={index} className="flex items-center mb-2">
                    <input
                      type="text"
                      value={point}
                      onChange={(e) => handleArrayChange(index, e.target.value, 'pointsTwo')}
                      className="w-full border p-2 mr-2"
                      placeholder={`Point ${index + 1}`}
                    />
                    <button
                      type="button"
                      onClick={() => removePoint(index, 'pointsTwo')}
                      className="text-red-500"
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button type="button" onClick={() => addPoint('pointsTwo')} className="text-blue-500">
                  Add Point
                </button>
              </div> */}

              {/* Speakers */}
              <div className="mb-4">
                <label className="block mb-2 font-bold">Speakers</label>
                {formData.speakers.map((speaker, speakerIndex) => (
                  <div key={speakerIndex} className="border p-4 mb-4">
                    <label className=''>Speaker name</label>
                    <input
                      type="text"
                      name={`speakers[${speakerIndex}].name`}
                      placeholder="Speaker Name"
                      className="w-full border p-2 mb-2"
                      value={speaker.name}
                      onChange={(e) => {
                        const updatedSpeakers = [...formData.speakers];
                        updatedSpeakers[speakerIndex].name = e.target.value;
                        setFormData({ ...formData, speakers: updatedSpeakers });
                      }}
                      required
                    />
                    <label className=''>Speaker Image</label>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleFileChange(e, speakerIndex)}
                    />
                    {speaker.image && <img src={speaker.image} alt={`Speaker ${speakerIndex}`} height="100px" width="100px" />}

                    {/* Speaker Details */}
                    {/* <div className="mb-4">
                      <label className="block mb-2 font-bold">Details</label>
                      {speaker.details.map((detail, detailIndex) => (
                        <div key={detailIndex} className="flex items-center mb-2">


                          <input
                            type="text"
                            value={detail}
                            onChange={(e) => handleSpeakerDetailChange(speakerIndex, detailIndex, e.target.value)}
                            className="w-full border p-2 mr-2"
                            placeholder={`Detail ${detailIndex + 1}`}
                          />
                          <button
                            type="button"
                            onClick={() => removeSpeakerDetail(speakerIndex, detailIndex)}
                            className="text-red-500"
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={() => addSpeakerDetail(speakerIndex)}
                        className="text-blue-500"
                      >
                        Add Detail
                      </button>
                    </div> */}

                    {/* <input
                      type="text"
                      name={`speakers[${speakerIndex}].profession`}
                      placeholder="Speaker Profession"
                      className="w-full border p-2 mb-2"
                      value={speaker.profession}
                      onChange={(e) => {
                        const updatedSpeakers = [...formData.speakers];
                        updatedSpeakers[speakerIndex].profession = e.target.value;
                        setFormData({ ...formData, speakers: updatedSpeakers });
                      }}
                      required
                    />
                    <input
                      type="text"
                      name={`speakers[${speakerIndex}].description`}
                      placeholder="Speaker description"
                      className="w-full border p-2 mb-2"
                      value={speaker.description}
                      onChange={(e) => {
                        const updatedSpeakers = [...formData.speakers];
                        updatedSpeakers[speakerIndex].description = e.target.value;
                        setFormData({ ...formData, speakers: updatedSpeakers });
                      }}
                      required
                    /> */}


                    <input
                      type="text"
                      name={`speakers[${speakerIndex}].profession`}
                      placeholder="Speaker Profession"
                      className="w-full border p-2 mb-2"
                      value={speaker.profession}
                      onChange={(e) => {
                        const updatedSpeakers = [...formData.speakers];
                        updatedSpeakers[speakerIndex].profession = e.target.value;
                        setFormData({ ...formData, speakers: updatedSpeakers });
                      }}
                      required
                    />
                    <input
                      type="text"
                      name={`speakers[${speakerIndex}].description`}
                      placeholder="Speaker Description"
                      className="w-full border p-2 mb-2"
                      value={speaker.description}
                      onChange={(e) => {
                        const updatedSpeakers = [...formData.speakers];
                        updatedSpeakers[speakerIndex].description = e.target.value;
                        setFormData({ ...formData, speakers: updatedSpeakers });
                      }}
                      required
                    />

                    <button
                      type="button"
                      onClick={() => removeSpeaker(speakerIndex)}
                      className="text-red-500"
                    >
                      Remove Speaker
                    </button>
                  </div>
                ))}
                <button type="button" onClick={addSpeaker} className="text-blue-500">
                  Add Speaker
                </button>
              </div>


              {/* Points One */}
              <div className="mb-4">
                <label className="block mb-2 font-bold">Points One</label>
                {formData.pointsOne.map((point, index) => (
                  <div key={index} className="flex items-center mb-2">
                    <input
                      type="text"
                      value={point}
                      onChange={(e) => handleArrayChange(index, e.target.value, 'pointsOne')}
                      className="w-full border p-2 mr-2"
                      placeholder={`Point ${index + 1}`}
                    />
                    <button
                      type="button"
                      onClick={() => removePoint(index, 'pointsOne')}
                      className="text-red-500"
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button type="button" onClick={() => addPoint('pointsOne')} className="text-blue-500">
                  Add Point
                </button>
              </div>


              {/* Submit Button */}
              <button type="submit" className="bg-blue-500 text-white p-2 mt-4">
                Submit
              </button>
            </form>
          </div>
        </Modal>

        <div className="table mt-4">
          <table className='rounded-md'>
            <div className="table_wrapper">
              <tr className='heading_row'>
                {/* <th>#ID</th> */}
                <th>IMAGE</th>
                <th>NAME</th>
                <th>PRICE</th>
                <th>DATE</th>
                <th>TIME</th>
                <th>EDIT</th>
                <th>DELETE</th>
              </tr>
              {webinar.map((course) => (
                <tr key={course.id}>
                  {/* <td>{`000${course.id}`}</td> */}
                  <td><img src={course.img} alt={course.name} /></td>
                  <td>{course.title}</td>
                  <td className='price'>₹{course.price}</td>
                  <td>{course.date}&nbsp; {course.year}</td>
                  <td>{course.time}</td>
                  <td className='edit_btn'>
                    <input type="button" value="Edit Info" onClick={() => handleEdit(course._id)} />
                  </td>
                  <td className='delete_btn'><input type="button" value="Delete"
                    onClick={() => handleDelete(course._id)} /></td>
                </tr>
              ))}
            </div>
          </table>
        </div>
      </div>
    </div>
  )
}


export default Webinar
