import React, { useState, useEffect } from "react";
import axios from "axios";
import "../courses/courses.css";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { IoIosCloseCircleOutline } from "react-icons/io";
import CourseIMG from "../../assets/courses.jpg";
import { setDriver } from "mongoose";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};

const ModulesData = () => {
    const navigate = useNavigate();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [module, setModule] = useState([]);

    const [course, setCourse] = useState("");
    const [title, setTitle] = useState("");
    const [img, setImg] = useState("");
    const [videonumber, setVideoNumber] = useState("");
    const [modulenumber, setModuleNumber] = useState("");
    const [discription, setDiscription] = useState("");
    const [tag, setTag] = useState("");
    const [file, setFile] = useState(null);

    const [courses, setcourses] = useState([]);
    const [teacherss, setTeacherss] = useState([]);
    const [coursess, setcoursess] = useState([]);

    const [loading, setLoading] = useState(false)
    const [err, errSet] = useState(false)
    const [imgerr, imgerrSet] = useState(false)





    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const result = await axios.get("https://innovationschool.io:5000/api/module", {
                    headers: {

                        authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                setcourses(result.data);
                //consol.log(result.data);
            } catch (error) {
                //consol.log("the error is ", error);
                if (error.response && error.response.status === 401) {
                    navigate("/");
                }
            }
        };
        fetchContacts();
    }, []);

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const result = await axios.get("https://innovationschool.io:5000/api/teacher", {
                    headers: {

                        authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                setTeacherss(result.data);
                //consol.log(result.data);
            } catch (error) {
                //consol.log("the error is ", error);
                if (error.response && error.response.status === 401) {
                    navigate("/");
                }
            }
        };
        fetchContacts();
    }, []);



    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const result = await axios.get("https://innovationschool.io:5000/api/courses", {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                setcoursess(result.data);
                //consol.log(result.data);
            } catch (error) {
                //consol.log("the error is ", error);
                if (error.response && error.response.status === 401) {
                    navigate("/");
                }
            }
        };
        fetchContacts();
    }, []);

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const result = await axios.get("https://innovationschool.io:5000/api/moduledata", {
                    headers: {

                        authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                setModule(result.data);
                //consol.log(result.data);
            } catch (error) {
                //consol.log("the error is ", error);
                if (error.response && error.response.status === 401) {
                    navigate("/");
                }
            }
        };
        fetchContacts();
    }, []);

    const handleEdit = (_id) => {
        navigate(`/editmoduledata/${_id}`);
    };

    let subtitle;

    const openModal = () => {
        setIsOpen(true);
    };

    const afterOpenModal = () => {
        subtitle.style.color = "#f00";
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const handleFileChangeImg = (e) => {
        setFile(e.target.files[0]);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "course":
                setCourse(value);
                break;
            case "title":
                setTitle(value);
                break;
            case "videonumber":
                setVideoNumber(value);
                break;
            case "modulenumber":
                setModuleNumber(value);
                break;
            case "discription":
                setDiscription(value);
                break;
            case "tag":
                setTag(value);
                break;
            default:
                break;
        }
    };





    const [user, setUser] = useState({
        course: '',
        image: '',
        video: '',
        title: '',
        url: '',
        metatitle: '',
        metadesc: '',
        author: '',
        keywords: '',
        price: '',
        priceDis: '',
        description: '',
        certificate: false,
        discription1: '',
        points1: [''],
        points2: [''],
        discription2: '',
        requirements: [''],
        rating: '',
        description3: [''],
        teacher: '',
        lecture: '',
        quizzes: '',
        skillLevel: '',
        topic: [{ name: '', lessons: [{ lessonName: '', pdf: '', video: '' }] }],
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setUser((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleArrayChange = (e, field, index) => {
        const { value } = e.target;
        setUser((prevData) => {
            const updatedArray = [...prevData[field]];
            updatedArray[index] = value;
            return { ...prevData, [field]: updatedArray };
        });
    };

    const handleAddPoint = (field) => {
        setUser((prevData) => ({
            ...prevData,
            [field]: [...prevData[field], ''],
        }));
    };

    const handleRemovePoint = (field, index) => {
        setUser((prevData) => {
            const updatedArray = [...prevData[field]];
            updatedArray.splice(index, 1);
            return { ...prevData, [field]: updatedArray };
        });
    };

    // const handleObjectArrayChange = (e, field, objectIndex, subField, subIndex) => {
    //     const { value } = e.target;
    //     setUser((prevData) => {
    //         const updatedObjectArray = [...prevData[field]];
    //         const updatedObject = { ...updatedObjectArray[objectIndex] };
    //         const updatedSubArray = [...updatedObject[subField]];
    //         updatedSubArray[subIndex] = value;
    //         updatedObject[subField] = updatedSubArray;
    //         updatedObjectArray[objectIndex] = updatedObject;
    //         return { ...prevData, [field]: updatedObjectArray };
    //     });
    // };


    const handleObjectArrayChange = (e, parentKey, parentIndex, childKey, childIndex, fieldKey) => {
        const value = e.target.value;
        setUser((prevData) => ({
            ...prevData,
            [parentKey]: prevData[parentKey]?.map((item, i) =>
                i === parentIndex
                    ? {
                        ...item,
                        [childKey]: item[childKey].map((childItem, j) =>
                            j === childIndex
                                ? { ...childItem, [fieldKey]: value } // This ensures `lessonName` is updated properly
                                : childItem
                        ),
                    }
                    : item
            ),
        }));
    };

    const handleObjectChange = (e, field, objectIndex, subField) => {
        const { value } = e.target;
        setUser((prevData) => {
            const updatedObjectArray = [...prevData[field]];
            const updatedObject = { ...updatedObjectArray[objectIndex] };
            updatedObject[subField] = value;
            updatedObjectArray[objectIndex] = updatedObject;
            return { ...prevData, [field]: updatedObjectArray };
        });
    };

    // const handleFileChange = (e, topicIndex, lessionIndex) => {
    //     const file = e.target.files[0];
    //     setUser((prevData) => {
    //         const updatedTopics = [...prevData.topic];
    //         const updatedlessions = [...updatedTopics[topicIndex].lession];
    //         updatedlessions[lessionIndex] = file.name;
    //         updatedTopics[topicIndex].files[lessionIndex] = file;
    //         return { ...prevData, topic: updatedTopics };
    //     });
    // };

    // const handleFileChange = (e, topicIndex, lessionIndex) => {
    //     const file = e.target.files[0];
    //     setUser((prevData) => ({
    //         ...prevData,
    //         topic: prevData.topic.map((item, i) =>
    //             i === topicIndex ? { ...item, lession: item.lession.map((lession, j) => (j === lessionIndex ? file.name : lession)) } : item
    //         ),
    //     }));
    // };

    // const [filesUrl, setFilesUrl] = useState('');

    // const onUploadfile = async () => {
    //     if (!file) {
    //         alert('Please select a file first.');
    //         return;
    //     }

    //     const formData = new FormData();
    //     formData.append('file', file);

    //     try {
    //         const response = await axios.post('https://innovationschool.io:5000/api/upload', formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //         });
    //         setFilesUrl(response.data.fileUrl);
    //         setUser((prevUser) => ({ ...prevUser, video: response.data.fileUrl }));
    //     } catch (error) {
    //         console.error('Error uploading file:', error);
    //     }
    // };

    // const handleFileChange = async (e, topicIndex, lessionIndex) => {
    //     const file = e.target.files[0];
    //     if (!file) {
    //         alert('Please select a file first.');
    //         return;
    //     }

    //     const formData = new FormData();
    //     formData.append('file', file);

    //     try {
    //         // Upload the file using the API
    //         const response = await axios.post('https://innovationschool.io:5000/api/upload', formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //         });

    //         const fileUrl = response.data.fileUrl;

    //         // Update the state with the uploaded file URL for the corresponding lession
    //         setUser((prevData) => ({
    //             ...prevData,
    //             topic: prevData.topic?.map((item, i) =>
    //                 i === topicIndex
    //                     ? {
    //                           ...item,
    //                           lession: item.lession.map((lession, j) =>
    //                               j === lessionIndex ? { ...lession, fileUrl } : lession
    //                           ),
    //                       }
    //                     : item
    //             ),
    //         }));
    //     } catch (error) {
    //         console.error('Error uploading file:', error);
    //     }
    // };


    const handleFileChange = async (e, topicIndex, lessonIndex, fileType) => {
        const file = e.target.files[0];
        if (!file) {
            alert('Please select a file first.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            // Upload the file using the API
            const response = await axios.post('https://innovationschool.io:5000/api/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const fileUrl = response.data.fileUrl;

            // Update the state with the uploaded file URL for the corresponding lesson
            setUser((prevData) => ({
                ...prevData,
                topic: prevData.topic.map((topic, i) =>
                    i === topicIndex
                        ? {
                            ...topic,
                            lessons: topic.lessons.map((lesson, j) =>
                                j === lessonIndex ? { ...lesson, [fileType]: fileUrl } : lesson
                            ),
                        }
                        : topic
                ),
            }));
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };



    // const handleAddlession = (index) => {
    //     setUser((prevData) => ({
    //         ...prevData,
    //         topic: prevData.topic?.map((item, i) =>
    //             i === index ? { ...item, lession: [...item.lession, ''] } : item
    //         ),
    //     }));
    // };


    const handleAddlession = (topicIndex) => {
        setUser((prevData) => ({
            ...prevData,
            topic: prevData.topic.map((topic, i) =>
                i === topicIndex
                    ? {
                        ...topic,
                        lessons: [
                            ...topic.lessons,
                            { lessonName: '', pdf: '', video: '' },  // New lesson structure
                        ],
                    }
                    : topic
            ),
        }));
    };


    // const handleRemovelession = (topicIndex, lessionIndex) => {
    //     setUser((prevData) => ({
    //         ...prevData,
    //         topic: prevData.topic.map((item, i) =>
    //             i === topicIndex ? { ...item, lession: item.lession.filter((_, j) => j !== lessionIndex) } : item
    //         ),
    //     }));
    // };


    const handleRemovelession = (topicIndex, lessonIndex) => {
        setUser((prevData) => ({
            ...prevData,
            topic: prevData.topic.map((topic, i) =>
                i === topicIndex
                    ? {
                        ...topic,
                        lessons: topic.lessons.filter((_, j) => j !== lessonIndex),
                    }
                    : topic
            ),
        }));
    };


    // const handleAddTopic = () => {
    //     setUser((prevData) => ({
    //         ...prevData,
    //         topic: [...prevData.topic, { name: '', lession: [''] }],
    //     }));
    // };

    const handleAddTopic = () => {
        setUser((prevData) => ({
            ...prevData,
            topic: [
                ...prevData.topic,
                { name: '', lessons: [{ lessonName: '', pdf: '', video: '' }] },  // New topic with one empty lesson
            ],
        }));
    };


    // const handleRemoveTopic = (topicIndex) => {
    //     setUser((prevData) => {
    //         const updatedTopics = [...prevData.topic];
    //         updatedTopics.splice(topicIndex, 1);
    //         return { ...prevData, topic: updatedTopics };
    //     });
    // };

    const handleRemoveTopic = (topicIndex) => {
        setUser((prevData) => {
            const updatedTopics = [...prevData.topic];
            updatedTopics.splice(topicIndex, 1);
            return { ...prevData, topic: updatedTopics };
        });
    };


    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     const formData = new FormData();
    //     formData.append("image", file);
    //     formData.append("course", course);
    //     formData.append("title", title);
    //     formData.append("videonumber", videonumber);
    //     formData.append("modulenumber", modulenumber);
    //     formData.append("discription", discription);
    //     formData.append("tag", tag);

    //     try {
    //         //consol.log("form data ", formData);
    //         const response = await axios.post(
    //             "https://innovationschool.io:5000/api/moduledata",
    //             formData,
    //             {
    //                 headers: {
    //                     "Content-Type": "multipart/form-data",
    //                 },
    //             }
    //         );
    //         setModule([...module, response.data]);
    //         //consol.log(response.data);
    //     } catch (error) {
    //         //consol.log(error);
    //         console.error("Error uploading the course data", error);
    //     }

    //     setCourse("");
    //     setTitle("");
    //     setVideoNumber("");
    //     setModuleNumber("");
    //     setDiscription("");
    //     setTag("");
    //     setFile(null);

    //     closeModal();
    // };

    const handleDelete = async (courseId) => {
        try {
            const response = await axios.delete(
                `https://innovationschool.io:5000/api/moduledata/${courseId}`, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            }
            );

            if (response.status === 200) {
                alert("Course deleted:");
                window.location.reload();
            } else {
                alert("Failed to delete course:");
                window.location.reload();
            }
        } catch (error) {
            //consol.log(error);
            console.error("Error deleting course:", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://innovationschool.io:5000/api/moduledata', user, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            }); // Replace with your API endpoint
            //consol.log('Response:', response.data);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
        closeModal();
    };



    // const [file, setFile] = useState(null);

    const [fileUrl, setFileUrl] = useState('');
    const [userimage, setuserimage] = useState('')
    const onFileChange = (e) => {
        setFile(e.target.files[0]);
    };
    const onUpload = async () => {
        if (!file) {
            alert('Please select a file first.');
            return;
        }
        setLoading(true)

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('https://innovationschool.io:5000/api/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setFileUrl(response.data.fileUrl);
            setUser((prevUser) => ({ ...prevUser, image: response.data.fileUrl }));
            setuserimage(response.data.fileUrl)
            setLoading(false)
            imgerrSet(false)
        } catch (error) {
            setLoading(false)
            imgerrSet(true)
            console.error('Error uploading file:', error);
        }
    };


    const [videourl, setVideourl] = useState('');
    const onFileChangevideo = (e) => {
        setFile(e.target.files[0]);
    };
    const onUploadvideo = async () => {
        if (!file) {
            alert('Please select a file first.');
            return;
        }

        setLoading(true)

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('https://innovationschool.io:5000/api/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setVideourl(response.data.fileUrl);
            setUser((prevUser) => ({ ...prevUser, video: response.data.fileUrl }));
            setLoading(false)
            errSet(false)
        } catch (error) {
            setLoading(false)
            errSet(true)
            console.error('Error uploading file:', error);
        }
    };
    return (
        <div>
            <div className="courses ml-2">
                <div className="courses_page_header">
                    <div className="courses_page_head">
                        <h2 className="h2 text-white">Available Module</h2>

                    </div>
                    <div className="courses_page_button">
                        <input type="button" value="Add Module" onClick={openModal} />
                    </div>
                </div>

                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="modal_wrapper">
                        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                            Fill the details!
                        </h2>
                        <button className="close_btn" onClick={closeModal}>
                            <IoIosCloseCircleOutline />
                        </button>
                        <form onSubmit={handleSubmit}>


                            <div className="bg-blue-100 p-4 rounded-md m-3">
                                <label>Cover Photo</label>
                                <input className="mb-3" type="file" accept="image/*" onChange={onFileChange} />
                                <span className="bg-blue-400 p-2 m-3 rounded-md cursor-pointer" onClick={onUpload}>{loading ? (
                                    <svg className="animate-spin h-5 w-5 mr-2 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                                    </svg>
                                ) : 'Upload'}
                                </span>

                                {imgerr && (
                                    <span className="text-red-500 mt-2">
                                        Error: Could not upload Image. Please try again . Use only png, jpeg , jpg, webp or gif
                                    </span>
                                )}
                                {
                                    userimage && (
                                        <img src={userimage} className="h-16 w-16 rounded-md mt-6" />
                                    )
                                }

                                {/* <button >Upload</button> */}
                                {/* {fileUrl && (
                                    <div>
                                        <p>File uploaded successfully:</p>
                                        <a href={fileUrl} target="_blank" rel="noopener noreferrer">View File</a>
                                        
                                    </div>
                                )} */}
                            </div>
                            <div className="bg-blue-100 p-4 rounded-md m-3">
                                <label>Cover video</label>
                                <input className="mb-3" type="file" accept="video/*" onChange={onFileChangevideo} />
                                <span
                                    className={`bg-blue-400 p-2 m-3 rounded-md cursor-pointer flex items-center justify-center ${loading ? 'opacity-50' : ''}`}
                                    onClick={onUploadvideo}
                                >
                                    {loading ? (
                                        <svg className="animate-spin h-5 w-5 mr-2 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                                        </svg>
                                    ) : 'Upload'}
                                </span>

                                {err && (
                                    <span className="text-red-500 mt-2">
                                        Error: Could not upload video. Please try again . Use only Mp4, Mkv or Avi
                                    </span>
                                )}

                                {/* <button >Upload</button> */}
                                {/* {fileUrl && (
                                    <div>
                                        <p>File uploaded successfully:</p>
                                        <a href={fileUrl} target="_blank" rel="noopener noreferrer">View File</a>
                                        
                                    </div>
                                )} */}
                            </div>
                            {/* <div>
                                <label>Course: </label>
                                <input
                                    type="text"
                                    name="module"
                                    value={user.module}
                                    onChange={handleChange}
                                />
                            </div> */}
                            <div className="bg-blue-100 p-4 rounded-md m-3">
                                <label>
                                    Course
                                </label>
                                <select name="module" value={user.module} onChange={handleChange}>
                                    <option value="" selected disabled>Select a course</option>
                                    {courses?.map((item, index) => (
                                        <option key={index} value={item.title}>
                                            {item.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="bg-yellow-200 p-4">
                                <h2 className="h2 ">For SEO</h2>



                                <div className="bg-blue-100 p-4 rounded-md m-3">
                                    <label>URL: </label>
                                    <input
                                        type="text"
                                        name="url"
                                        value={user.url}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="bg-blue-100 p-4 rounded-md m-3">
                                    <label>Meta title: </label>
                                    <input
                                        type="text"
                                        name="metatitle"
                                        value={user.metatitle}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="bg-blue-100 p-4 rounded-md m-3">
                                    <label>Meta Discription: </label>
                                    <input
                                        type="text"
                                        name="metadesc"
                                        value={user.metadesc}
                                        onChange={handleChange}
                                    />
                                </div>


                                <div className="bg-blue-100 p-4 rounded-md m-3">
                                    <label>Author: </label>
                                    <input
                                        type="text"
                                        name="author"
                                        value={user.author}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="bg-blue-100 p-4 rounded-md m-3">
                                    <label>keywords: </label>
                                    <input
                                        type="text"
                                        name="keywords"
                                        value={user.keywords}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="bg-blue-100 p-4 rounded-md m-3">
                                <label>Title: </label>
                                <input
                                    type="text"
                                    name="title"
                                    value={user.title}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="bg-blue-100 p-4 rounded-md m-3">
                                <label>Price: ₹ </label>
                                <input
                                    type="text"
                                    name="price"
                                    value={user.price}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="bg-blue-100 p-4 rounded-md m-3">
                                <label>Discounted Price: ₹ </label>
                                <input
                                    type="text"
                                    name="priceDis"
                                    value={user.priceDis}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="bg-blue-100 p-4 rounded-md m-3">
                                <label>Category: </label>
                                {/* <input
                                    type="text"
                                    name="course"
                                    value={user.course}
                                    onChange={handleChange}
                                /> */}

                                <select name="course" value={user.course} onChange={handleChange}>
                                    <option value="" selected disabled>Select a Category</option>
                                    {coursess?.map((item, index) => (
                                        <option key={index} value={item.title}>
                                            {item.title}
                                        </option>
                                    ))}
                                </select>

                            </div>
                            <div className="bg-blue-100 p-4 rounded-md m-3">
                                <label>Language: </label>
                                <input
                                    type="text"
                                    name="language"
                                    value={user.language}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="bg-blue-100 p-4 rounded-md m-3 flex">
                                Certificate:
                                <input
                                    type="checkbox"
                                    name="certificate"
                                    checked={user.certificate}
                                    onChange={handleChange}
                                />
                                <p className="text-sm text-red-600">Mark if this cource have cirtificate  </p>
                            </div>
                            <div className="bg-blue-100 p-4 rounded-md m-3">
                                <label>Description 1: </label>
                                <textarea
                                    name="discription1"
                                    value={user.discription1}
                                    onChange={handleChange}
                                    cols={90}
                                    rows={5}
                                />
                            </div>
                            <div className="bg-blue-100 p-4 rounded-md m-3">
                                <label>Points 1: </label>
                                {user.points1.map((point, index) => (
                                    <div key={index} >
                                        <input
                                            type="text"
                                            value={point}
                                            onChange={(e) => handleArrayChange(e, 'points1', index)}
                                        />
                                        <button className="bg-red-300 m-2 rounded-sm p-1" type="button" onClick={() => handleRemovePoint('points1', index)}>Remove</button>
                                    </div>
                                ))}
                                <button type="button" className="bg-blue-300 mt-2 rounded-sm p-1" onClick={() => handleAddPoint('points1')}>Add Point</button>
                            </div>
                            <div className="bg-blue-100 p-4 rounded-md m-3" >
                                <label>Points 2: </label>
                                {user.points2.map((point, index) => (
                                    <div key={index}>
                                        <input
                                            type="text"
                                            value={point}
                                            onChange={(e) => handleArrayChange(e, 'points2', index)}
                                        />
                                        <button className="bg-red-300 m-2 rounded-sm p-1" type="button" onClick={() => handleRemovePoint('points2', index)}>Remove</button>
                                    </div>
                                ))}
                                <button className="bg-blue-300 mt-2 rounded-sm p-1" type="button" onClick={() => handleAddPoint('points2')}>Add Point</button>
                            </div>
                            <div className="bg-blue-100 p-4 rounded-md m-3" >
                                <label>Description 2: </label>
                                <textarea
                                    name="discription2"
                                    value={user.discription2}
                                    onChange={handleChange}
                                    cols={90}
                                    rows={5}
                                />
                            </div>

                            <div className="bg-blue-100 p-4 rounded-md m-3" >
                                <label>Requirements: </label>
                                {user.requirements.map((point, index) => (
                                    <div key={index}>
                                        <input
                                            type="text"
                                            value={point}
                                            onChange={(e) => handleArrayChange(e, 'requirements', index)}
                                        />
                                        <button className="bg-red-300 m-2 rounded-sm p-1" type="button" onClick={() => handleRemovePoint('requirements', index)}>Remove</button>
                                    </div>
                                ))}
                                <button className="bg-blue-300 mt-2 rounded-sm p-1" type="button" onClick={() => handleAddPoint('requirements')}>Add Point</button>
                            </div>


                            <div className="bg-blue-100 p-4 rounded-md m-3">
                                <label>Rating: </label>
                                <input
                                    type="text"
                                    name="rating"
                                    value={user.rating}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="bg-blue-100 p-4 rounded-md m-3">
                                <label>Description 3: </label>
                                {user.description3.map((point, index) => (
                                    <div key={index}>
                                        <input
                                            type="text"
                                            value={point}
                                            onChange={(e) => handleArrayChange(e, 'description3', index)}
                                        />
                                        <button className="bg-red-300 m-2 rounded-sm p-1" type="button" onClick={() => handleRemovePoint('description3', index)}>Remove</button>
                                    </div>
                                ))}
                                <button className="bg-blue-300 mt-2 rounded-sm p-1" type="button" onClick={() => handleAddPoint('description3')}>Add Point</button>
                            </div>

                            <div className="bg-blue-100 p-4 rounded-md m-3">
                                <label>Teacher: </label>
                                {/* <input
                                    type="text"
                                    name="teacher"
                                    value={user.teacher}
                                    onChange={handleChange}
                                /> */}

                                <select name="teacher" value={user.teacher} onChange={handleChange}>
                                    <option value="" selected disabled>Select course teacher</option>
                                    {teacherss?.map((item, index) => (
                                        <option key={index} value={item.title}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>


                            <div className="bg-blue-100 p-4 rounded-md m-3" >
                                <label>Number of Lectures: </label>
                                <input
                                    type="number"
                                    name="lecture"
                                    value={user.lecture}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="bg-blue-100 p-4 rounded-md m-3" >
                                <label>Number of Quizzes: </label>
                                <input
                                    type="number"
                                    name="quizzes"
                                    value={user.quizzes}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="bg-blue-100 p-4 rounded-md m-3">
                                <label>Required Skill Level: </label>
                                <input
                                    type="text"
                                    name="skillLevel"
                                    value={user.skillLevel}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="bg-blue-100 p-4 rounded-md m-3">
                                <label>Topics: </label>
                                {user.topic.map((topic, topicIndex) => (
                                    <div key={topicIndex}>
                                        <label>Topic Name: </label>
                                        <input
                                            type="text"
                                            value={topic.name}
                                            onChange={(e) => handleObjectChange(e, 'topic', topicIndex, 'name')}
                                        />

                                        <div>
                                            <label>Lessons: </label>
                                            {topic.lessons.map((lesson, lessonIndex) => (
                                                <div key={lessonIndex} className="bg-white p-3 m-2 rounded-md">
                                                    <label>Lesson Name: </label>
                                                    <input
                                                        type="text"
                                                        value={lesson.lessonName}
                                                        onChange={(e) => handleObjectArrayChange(e, 'topic', topicIndex, 'lessons', lessonIndex, 'lessonName')}
                                                    />

                                                    <div>
                                                        <label>PDF: </label>
                                                        <input
                                                            type="file"
                                                            onChange={(e) => handleFileChange(e, topicIndex, lessonIndex, 'pdf')}
                                                        />
                                                        {lesson.pdf && <p>Uploaded PDF: <a href={lesson.pdf} target="_blank" rel="noopener noreferrer">View PDF</a></p>}
                                                    </div>

                                                    <div>
                                                        <label>Video: </label>
                                                        <input
                                                            type="file"
                                                            onChange={(e) => handleFileChange(e, topicIndex, lessonIndex, 'video')}
                                                        />
                                                        {lesson.video && <p>Uploaded Video: <a href={lesson.video} target="_blank" rel="noopener noreferrer">Watch Video</a></p>}
                                                    </div>

                                                    <button
                                                        className="bg-red-300 m-2 rounded-sm p-1"
                                                        type="button"
                                                        onClick={() => handleRemovelession(topicIndex, lessonIndex)}
                                                    >
                                                        Remove Lesson
                                                    </button>
                                                </div>
                                            ))}

                                            <button
                                                className="bg-blue-300 m-2 rounded-sm p-1"
                                                type="button"
                                                onClick={() => handleAddlession(topicIndex)}
                                            >
                                                Add Lesson
                                            </button>
                                        </div>

                                        <button
                                            className="bg-red-600 mt-2 rounded-sm p-1 text-white"
                                            type="button"
                                            onClick={() => handleRemoveTopic(topicIndex)}
                                        >
                                            Remove Topic
                                        </button>
                                    </div>
                                ))}

                                <button
                                    className="bg-green-600 mt-2 rounded-sm p-1 text-white"
                                    type="button"
                                    onClick={handleAddTopic}
                                >
                                    Add Topic
                                </button>
                            </div>



                            <button type="submit" className="bg-blue-500 text-white align-center justify-center item-center rounded-md p-2 " >Upload</button>
                        </form>
                    </div>
                </Modal>

                {/* <div className="table mt-4 rounded-sm">
                    <table className="rounded-sm">
                        <thead>
                            <tr className="heading_row">
                                <th>#ID</th>
                                <th>IMAGE</th>
                                <th>COURSES</th>
                                <th>NAME</th>
                                <th> Discounted PRICE</th>
                                <th>PRICE</th>
                                <th>RATING</th>
                                <th>EDIT</th>
                                <th>DELETE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {module.map((course) => (
                                <tr key={course._id}>
                                    <td>{`0${course._id}`}</td>
                                    <td>
                                        <img className="avimg" src={course.image} alt={course.title} height="100px" width="100px" />
                                    </td>
                                    <td>{course.course}</td>
                                    <td>{course.title}</td>

                                    <td >₹{course.priceDis}</td>
                                    <td >₹{course.price}</td>
                                    <td>{course.rating}</td>
                                    <td className="edit_btn">
                                        <input
                                            type="button"
                                            value="Edit Info"
                                            onClick={() => handleEdit(course._id)}
                                        />
                                    </td>
                                    <td className="delete_btn">
                                        <input
                                            onClick={() => handleDelete(course._id)}
                                            type="button"
                                            value="Delete"
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div> */}

                <div className="table mt-4">
                    <table className="min-w-full bg-white border border-gray-300 shadow-md rounded-lg overflow-hidden">
                        <thead className="bg-gray-200">
                            <tr className="text-left text-gray-600">
                                <th className="w-1/12 py-3 px-4 font-semibold">#ID</th>
                                <th className="w-2/12 py-3 px-4 font-semibold">IMAGE</th>
                                <th className="w-1/12 py-3 px-4 font-semibold">PRICE</th>
                                <th className="w-1/12 py-3 px-4 font-semibold">NAME</th>
                                {/* <th className="w-1/12 py-3 px-4 font-semibold">TAG</th> */}
                                <th className="w-1/12 py-3 px-4 font-semibold">EDIT</th>
                                <th className="w-1/12 py-3 px-4 font-semibold">DELETE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {module.map((course) => (
                                <tr key={course._id} className="border-b hover:bg-gray-100 transition duration-200">
                                    <td className="w-2/12 py-4 px-4 text-gray-700">INSC-{course._id.slice(0, 3)}-{course._id.slice(-4)}</td>
                                    <td className="w-1/12 py-4 px-4">
                                        <img
                                            src={course.image}
                                            alt={course.title}
                                            className=" w-4/12 object-cover rounded"
                                        />
                                    </td>
                                    <td className="w-1/12 py-4 px-4 text-gray-700">₹{course.priceDis}</td>
                                    <td className="w-4/12 py-4 px-4 text-gray-700"> <a target="_blank" className="hover:text-black" href={`https://innovationschool.io/all-courses/${course.course}/${course.title}`}>{course.title}</a></td>
                                    {/* <td className="w-1/12 py-4 px-4 text-gray-700">{course.tag}</td> */}

                                    <td className="w-2/12 py-4 px-4">
                                        <button
                                            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200"
                                            onClick={() => handleEdit(course._id)}
                                        >
                                            Edit Info
                                        </button>
                                    </td>
                                    <td className="w-1/12 py-4 px-4">
                                        <button
                                            className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-200"
                                            onClick={() => handleDelete(course._id)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>


                </div>
            </div>
        </div>
    );
};

export default ModulesData;
