// import React, { useState, useEffect } from 'react';
// import './editcourse.css';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';

// const EditCourse = () => {
//     const { id } = useParams();
//     const [user, setUser] = useState({
//         title: '',
//         price: '',
//         tag: '',
//         discription: ''
//     });

//     useEffect(() => {
//         const fetchCourse = async () => {
//             try {
//                 const response = await axios.get(`https://innovationschool.io:5000/api/courses?id=${id}`,{
//                     headers: {
//                       authorization: `Bearer ${localStorage.getItem('token')}`, // Include token if needed
//                     },
//                   });
//                 setUser(response.data); // Assuming response.data is an object containing course details
//             } catch (error) {
//                 console.error('Error fetching course:', error);
//             }
//         };
//         fetchCourse();
//     }, [id]);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setUser(prevUser => ({
//             ...prevUser,
//             [name]: value
//         }));
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault(); 
        

//         try {
//             const response = await axios.put(`https://innovationschool.io:5000/api/courses/${id}`, user,{
//                 headers: {
//                   authorization: `Bearer ${localStorage.getItem('token')}`, // Include token if needed
//                 },
//               });
//             //consol.log('Updated Course:', response.data);
//             alert('Course updated successfully');
//         } catch (error) {
//             console.error('Error updating course:', error);
//             alert('Failed to update course');
//         }
//     };

//     return (
//         <div>
//             <div className="edit">
//                 <div className="edit_wrapper">
//                     <h1 className='h1 text-white'>Edit Category</h1>
//                     <form onSubmit={handleSubmit}>
//                         <div>
//                             <label>Title </label>
//                             <input
//                                 type="text"
//                                 name="title"
//                                 value={user.title}
//                                 onChange={handleChange}
//                             />
//                         </div>
//                         <div>
//                             <label>Price: ₹ </label>
//                             <input
//                                 type="text"
//                                 name="price"
//                                 value={user.price}
//                                 onChange={handleChange}
//                             />
//                         </div>
//                         <div>
//                             <label>Tag </label>
//                             <input
//                                 type="text"
//                                 name="tag"
//                                 value={user.tag}
//                                 onChange={handleChange}
//                             />
//                         </div>
//                         <div>
//                             <label>Description </label>
//                             <input
//                                 type="text"
//                                 name="discription"
//                                 value={user.discription}
//                                 onChange={handleChange}
//                             />
//                         </div>
//                         <input className='update_btn' type="submit" value="Update" />
//                     </form>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default EditCourse;



import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const EditCourse = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [courses, setCourses] = useState([]);
  const [images, setImages] = useState();
  const [newImage, setNewImage] = useState(null);
  const [loading, setLoading] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);

//   useEffect(() => {
//     const fetchCourses = async () => {
//       try {
//         const result = await axios.get('https://innovationschool.io:5000/api/courses', {
//           headers: {
//             authorization: `Bearer ${localStorage.getItem('token')}`,
//           },
//         });
//         setCourses(result.data);
//       } catch (error) {
//         if (error.response?.status === 401) {
//           navigate('/');
//         }
//       }
//     };
//     fetchCourses();
//   }, [navigate]);

  useEffect(() => {
    const fetchModule = async () => {
      try {
        const result = await axios.get(`https://innovationschool.io:5000/api/courses?id=${id}`, {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const data = result.data;
        setUser(data);
        setImages(data.images || []);
      } catch (error) {
        if (error.response?.status === 401) {
          navigate('/');
        }
      }
    };
    fetchModule();
  }, [id, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

//   const handleFileChange = async (e) => {
//     const file = e.target.files[0];
//     if (!file) {
//       alert('Please select a file first.');
//       return;
//   }
//     if (file && file.type.startsWith('image/')) {
//       const formData = new FormData();
//       formData.append('file', file);
//       try {
//         // Upload the file using the API
//         const response = await axios.post('https://innovationschool.io:5000/api/upload', formData, {
//             headers: {
//                 'Content-Type': 'multipart/form-data',
//                 authorization: `Bearer ${localStorage.getItem('token')}`,
//             },
//         });
//         const fileUrl = response.data.fileUrl;
//         setNewImage(fileUrl);
//       setSelectedImage(URL.createObjectURL(file));

//       }
//         catch(error){console.log(error);
        

//         }
      
//     } else {
//       alert('Please select a valid image file.');
//     }
//   };

  const handleRemoveImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };


  const handleFileChangeAndUpload = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      alert('Please select a file first.');
      return;
    }

    setLoading(true); // Start loading indicator
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post('https://innovationschool.io:5000/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setUser((prevUser) => ({ ...prevUser, image: response.data.fileUrl })); // Update user with uploaded image URL
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setLoading(false); // Stop loading indicator
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();     

    try {
      const response = await axios.put(`https://innovationschool.io:5000/api/courses/${id}`,  user, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      alert('Course updated successfully');
      // navigate('/dashboard');
    } catch (error) {
      alert('Failed to update course');
    }
  };


  

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col items-center py-8">
      <div className="w-full max-w-4xl bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-2xl font-bold text-gray-700 mb-6">Edit Course</h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Images Section */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Photos (1000 X 1000 px)
            </label>
            <div className="flex flex-wrap gap-4 mb-4">
              {/* {images.map((img, index) => ( */}
                {/* <div  className="relative w-32 h-32">
                  <img
                    src={images}
                   
                    className="w-full h-full object-cover rounded-md"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveImage(images)}
                    className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                  >
                    ✕
                  </button>
                </div> */}
              {/* ))} */}
              {(user.image || selectedImage) && (
                <img
                  src={selectedImage || user.image}
                  alt="Preview"
                  className="ml-4 h-20 w-20 object-cover rounded-md border border-gray-300"
                />
              )}

            </div>
            <input
              type="file"
              onChange={handleFileChangeAndUpload}
              accept="image/*"
              className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border file:border-gray-300 file:text-sm file:font-medium file:bg-gray-100 file:hover:bg-gray-200"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">URL</label>
            <input
              type="text"
              name="url"
              value={user.url || ''}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"
            />
          </div>

          {/* Title */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Title</label>
            <input
              type="text"
              name="title"
              value={user.title || ''}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"
            />
          </div>

          {/* Course Dropdown */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Course</label>
            <select
              name="course"
              value={user.course || ''}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"
            >
              <option value="" disabled>
                Select a course
              </option>
              {courses.map((item, index) => (
                <option key={index} value={item.title}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>

          {/* Tag */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Tag</label>
            <input
              type="text"
              name="tag"
              value={user.tag || ''}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"
            />
          </div>

          {/* Description */}
          {/* <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Price</label>
            <input
            type='text'
              name="description"
              value={user.description || ''}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"
              rows="4"
            />
          </div> */}

          <button
            type="submit"
            className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Update
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditCourse;

