import React, { useState, useEffect } from 'react';
// import './editcourse.css'
import { useParams } from 'react-router-dom';
import axios from 'axios';

const EditWebinar = () => {
  const [file, setFile] = useState(null);
  
  const [fileUrl, setFileUrl] = useState('');
  // const [user, setUser] = useState({


  // });

  const { id } = useParams();
  // //consol.log("data is",id);
  const [user, setUser] = useState({});

  useEffect(() => {

    const fetchContacts = async () => {
      try {
        const result = axios.get(`https://innovationschool.io:5000/api/webinar?id=${id}`, {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`, // Include token if needed
          },
        });
        setUser((await result).data);
        //consol.log((await result).data);
      } catch (error) {
        //consol.log("the erro is ",error);
        if (error.response && error.response.status === 401) {

        }
      }
    };
    fetchContacts();

  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser(prevUser => ({
      ...prevUser,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`https://innovationschool.io:5000/api/webinar/${id}`, user, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`, // Include token if needed
        },
      });
      //consol.log('Updated Course:', response.data);
      alert('webinar updated successfully');
    } catch (error) {
      console.error('Error updating webinar:', error);
      alert('Failed to update course');
    }
  };



  const handleArrayChange = (index, value, field) => {
    const updatedArray = [...user[field]];
    updatedArray[index] = value;
    setUser({ ...user, [field]: updatedArray });
  };

  const handleSpeakerDetailChange = (speakerIndex, detailIndex, value) => {
    const updatedSpeakers = [...user.speakers];
    updatedSpeakers[speakerIndex].details[detailIndex] = value;
    setUser({ ...user, speakers: updatedSpeakers });
  };

  const addPoint = (field) => {
    setUser({ ...user, [field]: [...user[field], ''] });
  };

  const removePoint = (index, field) => {
    const updatedArray = user[field].filter((_, i) => i !== index);
    setUser({ ...user, [field]: updatedArray });
  };

  const addSpeaker = () => {
    setUser({
      ...user,
      speakers: [...user.speakers, { name: '', image: '', details: [''] }],
    });
  };

  const removeSpeaker = (index) => {
    const updatedSpeakers = user.speakers.filter((_, i) => i !== index);
    setUser({ ...user, speakers: updatedSpeakers });
  };

  const addSpeakerDetail = (speakerIndex) => {
    const updatedSpeakers = [...user.speakers];
    updatedSpeakers[speakerIndex].details.push('');
    setUser({ ...user, speakers: updatedSpeakers });
  };

  const removeSpeakerDetail = (speakerIndex, detailIndex) => {
    const updatedSpeakers = [...user.speakers];
    updatedSpeakers[speakerIndex].details = updatedSpeakers[speakerIndex].details.filter(
      (_, i) => i !== detailIndex
    );
    setUser({ ...user, speakers: updatedSpeakers });
  };



  const handleFileChange = async (e, speakerIndex) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const userFile = new user();
      userFile.append('file', file);

      const response = await fetch('https://innovationschool.io:5000/api/upload', {
        method: 'POST',
        body: userFile,
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`, // Include token if needed
        },
      });

      if (!response.ok) throw new Error('Upload failed');

      const result = await response.json();
      const imageUrl = result.fileUrl;

      const updatedSpeakers = [...user.speakers];
      updatedSpeakers[speakerIndex].image = imageUrl;
      setUser({ ...user, speakers: updatedSpeakers });
    } catch (error) {
      console.error('Upload error:', error);
    }
  };


  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const onUpload = async () => {
    if (!file) {
      alert('Please select a file first.');
      return;
    }

    const formDatas = new FormData();
    formDatas.append('file', file);

    try {
      const response = await axios.post('https://innovationschool.io:5000/api/upload', formDatas, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setFileUrl(response.data.fileUrl);
      // const updatedSpeakers = [...formData.img];
      // updatedSpeakers.img = response.data.fileUrl;
      // setFormData({ ...formData, img: updatedSpeakers });

      setUser((formData) => ({ ...formData, img: response.data.fileUrl }));
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };



  return (
    <div>
      <div className="edit">
        <div className="edit_wrapper">
          <h1>Edit Course</h1>
          <form onSubmit={handleSubmit}>
          <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Paid Or Not </label>
                <select
                  name="ispaid"
                  className="w-full border p-2 mb-4"
                  value={user.ispaid}
                  onChange={handleChange}
                  required
                >
                  <option value="" selected disabled>Select payment status</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>

              <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Link for Webinar </label>
                <input
                  type="text"
                  name="link"
                  placeholder="Link for Webinar"
                  className="w-full border p-2 mb-4"
                  value={user.link}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Title </label>
                <input
                  type="text"
                  name="title"
                  placeholder="Title"
                  className="w-full border p-2 mb-4"
                  value={user.title}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Card Photo</label>
                <input className="mb-3" type="file" onChange={onFileChange} />
                <span className="bg-blue-400 p-2 m-3 rounded-md cursor-pointer" onClick={onUpload}>Upload</span>
                {user.img && <img src={user.img} alt={`Cover img`} height="100px" width="100px" />}
              </div>
              <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Date </label>
                <input
                  type="datetime"
                  name="date"
                  placeholder="Date"
                  className="w-full border p-2 mb-4"
                  value={user.date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Year </label>
                <input
                  type="text"
                  name="year"
                  placeholder="Year"
                  className="w-full border p-2 mb-4"
                  value={user.year}
                  onChange={handleChange}
                />
              </div>


              <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Timing </label>
                <input
                  type="text"
                  name="time"
                  placeholder="Time"
                  className="w-full border p-2 mb-4"
                  value={user.time}
                  onChange={handleChange}
                  required
                /></div>


              <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Price </label>
                <input
                  type="text"
                  name="price"
                  placeholder="Price"
                  className="w-full border p-2 mb-4"
                  value={user.price}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>First heading </label>
                <input
                  type="text"
                  name="heading1"
                  placeholder="Heading 1"
                  className="w-full border p-2 mb-4"
                  value={user.heading1}
                  onChange={handleChange}
                  required
                />
              </div>


              <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Second heading </label>
                <input
                  type="text"
                  name="heading2"
                  placeholder="Heading 2"
                  className="w-full border p-2 mb-4"
                  value={user.heading2}
                  onChange={handleChange}
                  required
                />
              </div>



              <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Heading about wbinaar  </label>

                <input
                  type="text"
                  name="heading3"
                  placeholder="Heading 3"
                  className="w-full border p-2 mb-4"
                  value={user.heading3}
                  onChange={handleChange}
                  required
                />
              </div>

              {/* Points One */}
              <div className="mb-4">
                <label className="block mb-2 font-bold">Points One</label>
                {user?.pointsOne?.map((point, index) => (
                  <div key={index} className="flex items-center mb-2">
                    <input
                      type="text"
                      value={point}
                      onChange={(e) => handleArrayChange(index, e.target.value, 'pointsOne')}
                      className="w-full border p-2 mr-2"
                      placeholder={`Point ${index + 1}`}
                    />
                    <button
                      type="button"
                      onClick={() => removePoint(index, 'pointsOne')}
                      className="text-red-500"
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button type="button" onClick={() => addPoint('pointsOne')} className="text-blue-500">
                  Add Point
                </button>
              </div>


              <div className="bg-blue-100 p-4 rounded-md m-3">
                <label>Heading about wbinaar topic if required </label>
                <input
                  type="text"
                  name="heading4"
                  placeholder="Heading about wbinaar topic if required"
                  className="w-full border p-2 mb-4"
                  value={user.heading4}
                  onChange={handleChange}
                  required
                />
              </div>

              {/* Points Two */}
              <div className="mb-4">
                <label className="block mb-2 font-bold">Points Two</label>
                {user?.pointsTwo?.map((point, index) => (
                  <div key={index} className="flex items-center mb-2">
                    <input
                      type="text"
                      value={point}
                      onChange={(e) => handleArrayChange(index, e.target.value, 'pointsTwo')}
                      className="w-full border p-2 mr-2"
                      placeholder={`Point ${index + 1}`}
                    />
                    <button
                      type="button"
                      onClick={() => removePoint(index, 'pointsTwo')}
                      className="text-red-500"
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button type="button" onClick={() => addPoint('pointsTwo')} className="text-blue-500">
                  Add Point
                </button>
              </div>

              {/* Speakers */}
              <div className="mb-4">
                <label className="block mb-2 font-bold">Speakers</label>
                {user?.speakers?.map((speaker, speakerIndex) => (
                  <div key={speakerIndex} className="border p-4 mb-4">
                    <label className=''>Speaker name</label>
                    <input
                      type="text"
                      name={`speakers[${speakerIndex}].name`}
                      placeholder="Speaker Name"
                      className="w-full border p-2 mb-2"
                      value={speaker.name}
                      onChange={(e) => {
                        const updatedSpeakers = [...user.speakers];
                        updatedSpeakers[speakerIndex].name = e.target.value;
                        setUser({ ...user, speakers: updatedSpeakers });
                      }}
                      required
                    />
                    <label className=''>Speaker Image</label>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleFileChange(e, speakerIndex)}
                    />
                    {speaker.image && <img src={speaker.image} alt={`Speaker ${speakerIndex}`} height="100px" width="100px" />}

                    {/* Speaker Details */}
                    <div className="mb-4">
                      <label className="block mb-2 font-bold">Details</label>
                      {speaker?.details?.map((detail, detailIndex) => (
                        <div key={detailIndex} className="flex items-center mb-2">


                          <input
                            type="text"
                            value={detail}
                            onChange={(e) => handleSpeakerDetailChange(speakerIndex, detailIndex, e.target.value)}
                            className="w-full border p-2 mr-2"
                            placeholder={`Detail ${detailIndex + 1}`}
                          />
                          <button
                            type="button"
                            onClick={() => removeSpeakerDetail(speakerIndex, detailIndex)}
                            className="text-red-500"
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={() => addSpeakerDetail(speakerIndex)}
                        className="text-blue-500"
                      >
                        Add Detail
                      </button>
                    </div>

                    <button
                      type="button"
                      onClick={() => removeSpeaker(speakerIndex)}
                      className="text-red-500"
                    >
                      Remove Speaker
                    </button>
                  </div>
                ))}
                <button type="button" onClick={addSpeaker} className="text-blue-500">
                  Add Speaker
                </button>
              </div>
            <input className='update_btn' type="submit" value="Update" />
          </form>
        </div>
      </div>
    </div>
  )
}

export default EditWebinar
