// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --primary-clr: #992959;
    --yellow-clr: #FFFF00;
    --white-clr: #ffffff;
    --black-clr: #000000;
    --gray-clr: #F7F8F9;
    --btn-green: #046963;
    --btn-red: rgb(235, 39, 39);
}




.edit {
        position: absolute;
        left: 15%;
        /* width: 100%; */
        padding-right: 20px;
        padding-left: 40px;
        color: var(--white-clr);
        padding-top: 30px;
        padding-bottom: 30px;
        width: 70%;
}

.edit form {
    /* border: 1px solid var(--white-clr); */
    background: var(--white-clr);
    border-radius: 10px;
    padding: 50px;
}

.edit form label {
    /* border: 1px solid red; */
    display: block;
    font-weight: 600;
    color: var(--black-clr);

}
.edit form input {
    /* border: 1px solid red; */
    width: 100%;
    padding: 12px 20px;
    border: none;
    outline: none;
    border-radius: 4px;
    background-color: var(--gray-clr);
}


.update_btn {
    margin-top: 20px;
    width: 100px !important;
    background-color: var(--btn-green) !important;
    color: var(--white-clr) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Edit/editcourse.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,qBAAqB;IACrB,oBAAoB;IACpB,oBAAoB;IACpB,mBAAmB;IACnB,oBAAoB;IACpB,2BAA2B;AAC/B;;;;;AAKA;QACQ,kBAAkB;QAClB,SAAS;QACT,iBAAiB;QACjB,mBAAmB;QACnB,kBAAkB;QAClB,uBAAuB;QACvB,iBAAiB;QACjB,oBAAoB;QACpB,UAAU;AAClB;;AAEA;IACI,wCAAwC;IACxC,4BAA4B;IAC5B,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,2BAA2B;IAC3B,cAAc;IACd,gBAAgB;IAChB,uBAAuB;;AAE3B;AACA;IACI,2BAA2B;IAC3B,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,iCAAiC;AACrC;;;AAGA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,6CAA6C;IAC7C,kCAAkC;AACtC","sourcesContent":[":root {\n    --primary-clr: #992959;\n    --yellow-clr: #FFFF00;\n    --white-clr: #ffffff;\n    --black-clr: #000000;\n    --gray-clr: #F7F8F9;\n    --btn-green: #046963;\n    --btn-red: rgb(235, 39, 39);\n}\n\n\n\n\n.edit {\n        position: absolute;\n        left: 15%;\n        /* width: 100%; */\n        padding-right: 20px;\n        padding-left: 40px;\n        color: var(--white-clr);\n        padding-top: 30px;\n        padding-bottom: 30px;\n        width: 70%;\n}\n\n.edit form {\n    /* border: 1px solid var(--white-clr); */\n    background: var(--white-clr);\n    border-radius: 10px;\n    padding: 50px;\n}\n\n.edit form label {\n    /* border: 1px solid red; */\n    display: block;\n    font-weight: 600;\n    color: var(--black-clr);\n\n}\n.edit form input {\n    /* border: 1px solid red; */\n    width: 100%;\n    padding: 12px 20px;\n    border: none;\n    outline: none;\n    border-radius: 4px;\n    background-color: var(--gray-clr);\n}\n\n\n.update_btn {\n    margin-top: 20px;\n    width: 100px !important;\n    background-color: var(--btn-green) !important;\n    color: var(--white-clr) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
