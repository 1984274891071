import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Modal from "react-modal";
import './quizes.css'
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Quizes = () => {
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [module, setModule] = useState([]);

  let subtitle;
  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    subtitle.style.color = "#f00";
  };


  const [quizeData, setQuizeData] = useState([])

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const result = await axios.get("https://innovationschool.io:5000/api/quizzes", {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setQuizeData(result.data);
        //consol.log(result.data);
      } catch (error) {
        //consol.log("the error is ", error);
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      }
    };
    fetchContacts();
  }, []);



  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const result = await axios.get("https://innovationschool.io:5000/api/moduledata", {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setModule(result.data);
        //consol.log(result.data);
      } catch (error) {
        //consol.log("the error is ", error);
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      }
    };
    fetchContacts();
  }, []);
  const [questions, setQuestions] = useState([
    { id: uuidv4(), question: '', options: ['', '', '', ''], answer: '' }
  ]);
  const [user, setUser] = useState()

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUser((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleQuestionChange = (index, event) => {
    const newQuestions = questions.slice();
    newQuestions[index].question = event.target.value;
    setQuestions(newQuestions);
  };

  const handleOptionChange = (qIndex, oIndex, event) => {
    const newQuestions = questions.slice();
    newQuestions[qIndex].options[oIndex] = event.target.value;
    setQuestions(newQuestions);
  };

  const handleAnswerChange = (index, event) => {
    const newQuestions = questions.slice();
    newQuestions[index].answer = event.target.value;
    setQuestions(newQuestions);
  };

  const addQuestion = () => {
    setQuestions([...questions, { id: uuidv4(), question: '', options: ['', '', '', ''], answer: '' }]);
  };



  const handleSubmit = async (event) => {
    event.preventDefault();
    const quizData = {
      module: user.module,
      questions: questions.map((q) => ({
        id: q.id,
        question: q.question,
        options: q.options,
        answer: q.answer
      })),

    };

    console.log('Quize is', quizData)

    try {
      const response = await axios.post('https://innovationschool.io:5000/api/quizzes', quizData, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }); // Replace with your API endpoint
      //consol.log('Response:', response.data);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
    closeModal();

    // Mock API endpoint for testing with Postman

  };


  const handleDelete = async (courseId, title) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete the Quize for: "${title}"? This action cannot be undone.`);
  
    if (!confirmDelete) {
      return; // If the user clicks "Cancel", stop the function
    }
  
    try {
      const response = await axios.delete(
        `https://innovationschool.io:5000/api/quizzes/${courseId}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
      if (response.status === 200) {
        alert(`Course "${title}" deleted successfully.`);
        window.location.reload(); // Reload the page after successful deletion
      } else {
        alert(`Failed to delete course "${title}".`);
        window.location.reload();
      }
    } catch (error) {
      console.error("Error deleting course:", error);
      alert(`An error occurred while deleting the course "${title}".`);
    }
  };
  

  const handleEdit = (_id) => {
    navigate(`/editquizz/${_id}`);
  };
  return (
    <div>
      <div className="courses">
        <div className="courses_page_header ">
          <div className="courses_page_head">
            <h2 className="h2 text-stone-50 ml-3">Available Quizes</h2>
          </div>
          <div className="courses_page_button">
            <input type="button" value="Add New Quizes" onClick={openModal} />
          </div>
        </div>

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="create-quiz modal_wrapper mt-2 w-full">
            <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Fill the details!</h2>
            <button className="close_btn" onClick={closeModal}>
              <IoIosCloseCircleOutline />
            </button>
            <form onSubmit={handleSubmit}>
              <label>Module</label>
              <select name="module" value={user} onChange={handleChange}>
                <option value="" selected disabled>Select a course</option>
                {module?.map((item, index) => (
                  <option key={index} value={item.title}>
                    {item.title}
                  </option>
                ))}
              </select>
              {questions.map((q, qIndex) => (
                <div key={q.id} className="question-card">
                  <label>
                    <h5>Question:</h5>
                    <input
                      className='question_inp'
                      type="text"
                      value={q.question}
                      onChange={(e) => handleQuestionChange(qIndex, e)}
                      placeholder='Enter your question'
                    />
                  </label>
                  <div className="options">
                    {q.options.map((option, oIndex) => (
                      <label key={oIndex}>
                        <p>Option {oIndex + 1}:</p>
                        <input
                          type="text"
                          value={option}
                          onChange={(e) => handleOptionChange(qIndex, oIndex, e)}
                          placeholder={`Enter option ${oIndex + 1}`}
                        />
                      </label>
                    ))}
                  </div>
                  <label className='correct_ans'>
                    Correct Answer:
                    <input
                      type="text"
                      value={q.answer}
                      onChange={(e) => handleAnswerChange(qIndex, e)}
                      placeholder='Enter correct answer'
                    />
                  </label>
                </div>
              ))}
              <input type="button" value="Add Question" onClick={addQuestion} />
              <input type="submit" value="Create Quiz" />
            </form>
          </div>
        </Modal>

        <div className="table mt-4 ml-3 rounded-sm">
          <table className="rounded-sm">
            <thead>
              <tr className="heading_row">
                <th>#ID</th>

                <th>Module</th>
                <th>Number of question</th>
                <th>EDIT</th>
                <th>DELETE</th>
              </tr>
            </thead>
            <tbody>
              {quizeData?.map((course) => (
                <tr key={course._id}>
                  <td>{`0${course._id}`}</td>

                  <td>{course.module}</td>

                  <td>{course.questions?.length}</td>
                  <td className="edit_btn">
                    <input
                      type="button"
                      value="Edit Info"
                      onClick={() => handleEdit(course._id)}
                    />
                  </td>
                  <td className="delete_btn">
                    <input
                      onClick={() => handleDelete(course._id, course.module)}
                      type="button"
                      value="Delete"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>


      </div>
    </div>
  )
}

export default Quizes
