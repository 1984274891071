import React, { useState, useEffect } from "react";
import axios from "axios";
import "./modules.css";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { IoIosCloseCircleOutline } from "react-icons/io";
import CourseIMG from "../../assets/courses.jpg";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: '50%',
  },
};

const Modules = () => {
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [module, setModule] = useState([]);

  const [course, setCourse] = useState("");
  const [courses, setcourses] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [title, setTitle] = useState("");
  const [url, seturl] = useState("");
  const [img, setImg] = useState("");
  const [videonumber, setVideoNumber] = useState("");
  const [modulenumber, setModuleNumber] = useState("");
  const [discription, setDiscription] = useState("");
  const [tag, setTag] = useState("");
  const [file, setFile] = useState(null);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const result = await axios.get("https://innovationschool.io:5000/api/module", {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`, // Include token if needed
          },
        });
        setModule(result.data);
        //consol.log(result.data);
      } catch (error) {
        //consol.log("the error is ", error);
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      }
    };
    fetchContacts();
  }, []);


  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const result = await axios.get("https://innovationschool.io:5000/api/courses", {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`, // Include token if needed
          },
        });
        setcourses(result.data);
        //consol.log(result.data);
      } catch (error) {
        //consol.log("the error is ", error);
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      }
    };
    fetchContacts();
  }, []);

  const handleEdit = (_id) => {
    navigate(`/editmodule/${_id}`);
  };

  let subtitle;

  const openModal = () => {
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    subtitle.style.color = "#f00";
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleFileChangeimg = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      setFile(selectedFile); // Set the selected file
      setSelectedImage(URL.createObjectURL(selectedFile)); // Create a preview URL
    } else {
      alert('Please select a valid image file.');
      setSelectedImage(null); // Reset the image preview
      setFile(null); // Reset the file state
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "course":
        setCourse(value);
        break;
      case "title":
        setTitle(value);
        break;
      case "url":
        seturl(value);
        break;
      case "videonumber":
        setVideoNumber(value);
        break;
      case "modulenumber":
        setModuleNumber(value);
        break;
      case "discription":
        setDiscription(value);
        break;
      case "tag":
        setTag(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", file);
    formData.append("course", course);
    formData.append("title", title);
    formData.append("url", url);
    formData.append("videonumber", videonumber);
    formData.append("modulenumber", modulenumber);
    formData.append("discription", discription);
    formData.append("tag", tag);

    try {
      //consol.log("form data ", formData)
      const response = await axios.post(
        "https://innovationschool.io:5000/api/module",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setModule([...module, response.data]);
      //consol.log(response.data);
    } catch (error) {
      //consol.log(error);
      console.error("Error uploading the course data", error);
    }

    setCourse("");
    setTitle("");
    setVideoNumber("");
    setModuleNumber("");
    setDiscription("");
    setTag("");
    setFile(null);

    closeModal();
  };

  const handleDelete = async (courseId) => {
    try {
      const response = await axios.delete(`https://innovationschool.io:5000/api/module/${courseId}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`, // Include token if needed
        },
      });

      if (response.status === 200) {
        alert("Course deleted:");
        window.location.reload();
      } else {
        alert("Failed to delete course:");
        window.location.reload();
      }
    } catch (error) {
      //consol.log(error);
      console.error("Error deleting course:", error);
    }
  };

  return (
    <div className="p-2">
      <div className="courses">
        <div className="courses_page_header">
          <div className="courses_page_head">
            <h2 className="h2 text-white p-2 rounded-md">Available Courses</h2>
            {/* <p>Whole data about your business here</p> */}
          </div>
          <div className="courses_page_button">
            <input type="button" value="Add Courses" onClick={openModal} />
          </div>
        </div>

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="modal_wrapper">
            <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>
            <button className="close_btn" onClick={closeModal}>
              <IoIosCloseCircleOutline />
            </button>
            <form className="modal_form space-y-6 p-8 bg-gradient-to-r from-[#ECD8E1] to-[#992959] rounded-lg shadow-lg border border-gray-200" onSubmit={handleSubmit}>
              <h2 className="text-3xl font-bold text-center underline text-gray-700 p-2 rounded-md">Create Courses</h2>

              <div className="flex flex-col">
                <label className="text-gray-700 bg-white p-2 rounded-md font-medium mb-1">
                  Upload Image
                  <div className="mt-1 flex items-center">
                    <input
                      type="file"
                      id="myFile"
                      name="image"
                      onChange={handleFileChangeimg}
                      accept="image/*" // Accept only image files
                      className="hidden" // Hide the default file input
                    />
                    <label htmlFor="myFile" className="cursor-pointer block w-full p-3 text-center text-gray-700 border border-gray-300 rounded-md shadow-sm bg-white hover:bg-gray-100 focus:ring focus:ring-blue-500 transition duration-200">
                      Choose File
                    </label>
                    {selectedImage && (
                      <img src={selectedImage} alt="Preview" className="ml-4 h-20 w-20 object-cover rounded-md border border-gray-300" />
                    )}
                  </div>
                </label>
              </div>

              <div className="flex flex-col">
                <label className="text-gray-700 bg-white p-2 rounded-md font-medium mb-1">
                  Course
                  <select name="course" value={course} onChange={handleInputChange} className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-500 transition duration-200">
                    <option value="" disabled>Select a course</option>
                    {courses?.map((item, index) => (
                      <option key={index} value={item.title}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <div className="flex flex-col">
                <label className="text-gray-700 bg-white p-2 rounded-md font-medium mb-1">
                  URL
                  <input
                    type="text"
                    placeholder="Enter URL"
                    name="url"
                    value={url}
                    onChange={handleInputChange}
                    className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-500 transition duration-200"
                  />
                </label>
              </div>

              <div className="flex flex-col">
                <label className="text-gray-700 bg-white p-2 rounded-md font-medium mb-1">
                  Title
                  <input
                    type="text"
                    placeholder="Enter title"
                    name="title"
                    value={title}
                    onChange={handleInputChange}
                    className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-500 transition duration-200"
                  />
                </label>
              </div>

              <div className="flex flex-col">
                <label className="text-gray-700 bg-white p-2 rounded-md font-medium mb-1">
                  Video Number
                  <input
                    type="text"
                    placeholder="Enter video number"
                    name="videonumber"
                    value={videonumber}
                    onChange={handleInputChange}
                    className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-500 transition duration-200"
                  />
                </label>
              </div>

              <div className="flex flex-col">
                <label className="text-gray-700 bg-white p-2 rounded-md font-medium mb-1">
                  Module Number
                  <input
                    type="text"
                    placeholder="Enter module number"
                    name="modulenumber"
                    value={modulenumber}
                    onChange={handleInputChange}
                    className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-500 transition duration-200"
                  />
                </label>
              </div>

              <div className="flex flex-col">
                <label className="text-gray-700 bg-white p-2 rounded-md font-medium mb-1">
                  Description
                  <input
                    type="text"
                    placeholder="Enter description"
                    name="discription"
                    value={discription}
                    onChange={handleInputChange}
                    className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-500 transition duration-200"
                  />
                </label>
              </div>

              <div className="flex flex-col">
                <label className="text-gray-700 bg-white p-2 rounded-md font-medium mb-1">
                  Tag
                  <input
                    type="text"
                    placeholder="Enter tag"
                    name="tag"
                    value={tag}
                    onChange={handleInputChange}
                    className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-500 transition duration-200"
                  />
                </label>
              </div>

              <button
                type="submit"
                className="w-full px-4 py-3 text-lg font-semibold text-black bg-yellow-500 rounded-md hover:bg-yellow-700 focus:outline-none focus:ring focus:ring-blue-300 transition duration-200"
              >
                Create Course
              </button>
            </form>

          </div>
        </Modal>

        <div className="table mt-4">
          <table className="min-w-full bg-white border border-gray-300 shadow-md rounded-lg overflow-hidden">
            <thead className="bg-gray-200">
              <tr className="text-left text-gray-600">
                <th className="w-1/12 py-3 px-4 font-semibold">#ID</th>
                <th className="w-2/12 py-3 px-4 font-semibold">IMAGE</th>
                <th className="w-1/12 py-3 px-4 font-semibold">COURSES</th>
                <th className="w-1/12 py-3 px-4 font-semibold">NAME</th>
                <th className="w-1/12 py-3 px-4 font-semibold">PRICE</th>
                <th className="w-1/12 py-3 px-4 font-semibold">EDIT</th>
                <th className="w-1/12 py-3 px-4 font-semibold">DELETE</th>
              </tr>
            </thead>
            <tbody>
              {module.map((course) => (
                <tr key={course._id} className="border-b hover:bg-gray-100 transition duration-200">
                  <td className="w-2/12 py-4 px-4 text-gray-700">INSC-{course._id.slice(0, 3)}-{course._id.slice(-4)}</td>
                  <td className="w-1/12 py-4 px-4">
                    <img
                      src={course.image}
                      alt={course.title}
                      className=" w-4/12 object-cover rounded"
                    />
                  </td>
                  <td className="w-1/12 py-4 px-4 text-gray-700">{course.course}</td>
                  <td className="w-4/12 py-4 px-4 text-gray-700">{course.title}</td>
                  <td className="w-1/12 py-4 px-4 text-gray-700">₹{course.discription}</td>

                  <td className="w-2/12 py-4 px-4">
                    <button
                      className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200"
                      onClick={() => handleEdit(course._id)}
                    >
                      Edit Info
                    </button>
                  </td>
                  <td className="w-1/12 py-4 px-4">
                    <button
                      className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-200"
                      onClick={() => handleDelete(course._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>


        </div>
      </div>
    </div>
  );
};

export default Modules;
