import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Modal2 = ({ isOpen, onClose }) => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [editCategory, setEditCategory] = useState({ _id: null, name: '' });

  // Fetch categories from the API
  useEffect(() => {
    if (isOpen) {
      fetchCategories();
    }
  }, [isOpen]);

  const fetchCategories = async () => {
    try {
      const response = await axios.get('/api/categories');
      setCategories(response.data.data);
    } catch (error) {
      console.error('Error fetching categories:', error.response?.data?.message || error.message);
    }
  };

  const handleAddCategory = async () => {
    if (newCategory.trim() !== '') {
      try {
        const response = await axios.post('/api/categories', { name: newCategory },{
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setCategories([...categories, response.data.data]);
        setNewCategory('');
      } catch (error) {
        console.error('Error adding category:', error.response?.data?.message || error.message);
      }
    }
  };

  const handleEditCategory = async (_id) => {
    if (editCategory.name.trim() !== '') {
      try {
        const response = await axios.put(`/api/categories/${_id}`, { name: editCategory.name },{
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setCategories(
          categories.map((cat) =>
            cat._id === _id ? { ...cat, name: response.data.data.name } : cat
          )
        );
        setEditCategory({ _id: null, name: '' });
        fetchCategories();
      } catch (error) {
        console.error('Error editing category:', error.response?.data?.message || error.message);
      }
    }
  };

  const handleDeleteCategory = async (_id) => {
    try {
      await axios.delete(`/api/categories/${_id}`,{
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setCategories(categories.filter((cat) => cat._id !== _id));
    } catch (error) {
      console.error('Error deleting category:', error.response?.data?.message || error.message);
    }
  };

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 ${
        isOpen ? 'block' : 'hidden'
      }`}
    >
      <div className="bg-white rounded-lg shadow-lg p-6 w-96">
        <h2 className="text-lg font-bold mb-4">Manage Categories</h2>

        {/* Add Category */}
        <div className="mb-4">
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            placeholder="Add new category"
            className="border rounded px-3 py-2 w-full mb-2"
          />
          <button
            onClick={handleAddCategory}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 w-full"
          >
            Add Category
          </button>
        </div>

        {/* List Categories */}
        <div className="space-y-2">
          {categories.map((category) => (
            <div
              key={category._id}
              className="flex items-center justify-between border p-2 rounded"
            >
              {editCategory._id === category._id ? (
                <input
                  type="text"
                  value={editCategory.name}
                  onChange={(e) => setEditCategory({ ...editCategory, name: e.target.value })}
                  className="border rounded px-2 py-1 flex-1 mr-2"
                />
              ) : (
                <span>{category.name}</span>
              )}
              <div className="flex space-x-2">
                {editCategory._id === category._id ? (
                  <button
                    onClick={() => handleEditCategory(category._id)}
                    className="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600"
                  >
                    Save
                  </button>
                ) : (
                  <button
                    onClick={() => setEditCategory({ _id: category._id, name: category.name })}
                    className="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600"
                  >
                    Edit
                  </button>
                )}
                <button
                  onClick={() => handleDeleteCategory(category._id)}
                  className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Close Button */}
        <button
          onClick={onClose}
          className="mt-4 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 w-full"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal2;
