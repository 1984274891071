import React, { useState, useEffect } from 'react'
import './dashboard.css'
import { FaHome } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import { IoPersonSharp } from "react-icons/io5";
import { MdMessage } from "react-icons/md";
import { FaCubes } from "react-icons/fa";
import { FaLightbulb } from "react-icons/fa";
import { FaGraduationCap } from "react-icons/fa6";
import { BiGroup, BiSolidCategoryAlt } from "react-icons/bi";
import { CiStreamOn } from "react-icons/ci";
import { IoIosLogOut } from "react-icons/io";
import DashboradHome from '../dashborad_home/DashboradHome';
import AddStudent from '../addStudent/AddStudent';
import Orders from '../orders/Orders';
import Courses from '../courses/Courses';
import Modules from '../modules/Modules';
import Teachers from '../teachers/Teachers';
import Reviews from '../reviews/Reviews';
import Quizes from '../quizes/Quizes';
import ModulesData from '../modulesdata/ModulesData';
import Webinar from '../webinar/Webinar';
import { FaBlog } from "react-icons/fa";
import Blogs from '../blogs/Blog';
import axios from 'axios';
import { MDBContainer, MDBCol, MDBRow, MDBBtn, MDBIcon, MDBInput, MDBCheckbox } from 'mdb-react-ui-kit';


const Dashboard = () => {

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };
    const isAuthenticated = !!localStorage.getItem('token');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState("");

    console.log(username, password)

    // Replace with your actual authentication logic

    const handleLogin = async (event) => {
        event.preventDefault(); // Prevent default form submission
        try {
            console.log(username, password); // Debugging: Log username and password
            const response = await axios.post('https://innovationschool.io:5000/api/login', { username, password });
            localStorage.setItem('token', response.data.token); // Store token
            console.log(response); // Log response for debugging
            alert('Login successful');
            window.location.reload() // Alert user
        } catch (error) {
            console.error(error); // Log error for debugging
            setError('Error logging in'); // Set error message for user feedback
            alert('Error logging in'); // Alert user on error
        }
    };

    const logout= async (event)=>{
        event.preventDefault();
        try{
            localStorage.removeItem('token');
            alert('Logout successful');
            window.location.reload()
        }
        catch (erro) {
            console.log(error)
            setError('Error logging out'); // Set error message for user feedback
            alert('Error logging out');
            
        }
    }

    const [animateLogo, setAnimateLogo] = useState(false);

    useEffect(() => {
        // Trigger the animation after a small delay when the component mounts
        setTimeout(() => {
            setAnimateLogo(true);
        }, 500);
    }, []);

    if (!isAuthenticated) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen p-4">
                {/* Animated Logo */}
                <div
                    className={`transition-transform duration-1000 ease-out ${animateLogo ? 'translate-y-0 opacity-100' : 'translate-y-20 opacity-0'
                        }`}
                >
                    <img
                        src="https://innovationschool.io/assets/bgsvg/Mascot.png"
                        alt="logoOne"
                        height={100}
                        width={100}
                        className="animate-slide-up"
                    />
                </div>

                {/* Main Logo */}
                <img
                    src="https://innovationschool.io/assets/logo-2.png"
                    alt="Logo"
                    height={300}
                    width={300}
                    className=""
                />

                {/* Login Form */}
                <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-lg">
                    <h2 className="text-3xl font-bold text-center text-gray-800">Login to Your Account</h2>
                    {error && <p className="text-red-500 text-center">{error}</p>}
                    <form className="space-y-6" onSubmit={handleLogin}>
                        <div>
                            <label className="block text-sm font-medium text-gray-700" htmlFor="username">
                                Username
                            </label>
                            <input
                                type="text"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="you@example.com"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700" htmlFor="password">
                                Password
                            </label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="••••••••"
                            />
                        </div>
                        <button
                            type="submit"
                            className="w-full px-4 py-2 font-semibold text-white bg-[#992959] rounded-md shadow-md hover:bg-[#b6547c] focus:outline-none focus:ring-2 focus:ring-blue-400"
                        >
                            Login
                        </button>
                    </form>
                </div>
            </div>
        );
    }


    return (
        // <div className='dashboard-container'>
        <div className={`dashboard-container ${sidebarOpen ? 'sidebar-open' : ''}`}>
            <div>

                <img src="https://innovationschool.io/assets/logo-2.png" alt="" className='ml-10' width={150} />
            </div>
            <section className="dashboard">
                <div class="dashboard_wrapper">
                    {/* <div class="col-md-2 mb-3 sidebar"> */}
                    <div className="sidebar-toggler" onClick={toggleSidebar}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class={`col-md-2 mb-3 sidebar ${sidebarOpen ? 'open' : ''}`}>
                        <ul class="nav nav-pills flex-column" id="experienceTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="dashborad-home-tab" data-toggle="tab" href="#dashboard_home" role="tab" aria-controls="home" aria-selected="true">
                                    <span><FaHome /></span> Dashboard</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="courses-tab" data-toggle="tab" href="#courses" role="tab" aria-controls="courses" aria-selected="false">
                                    <span><BiSolidCategoryAlt /></span> Categories</a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" id="modules-tab" data-toggle="tab" href="#modules" role="tab" aria-controls="modules" aria-selected="false">
                                    <span><FaCubes /></span> Courses</a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" id="Module-data-tab" data-toggle="tab" href="#Module-data" role="tab" aria-controls="Module-data" aria-selected="false">
                                    <span><FaGraduationCap /></span> Module</a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" id="addstudent-tab" data-toggle="tab" href="#addstudent" role="tab" aria-controls="addstudent" aria-selected="false">
                                    <span><BiGroup /></span> AddStudent</a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" id="orders-tab" data-toggle="tab" href="#orders" role="tab" aria-controls="orders" aria-selected="false">
                                    <span><FaShoppingCart /></span> Orders</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="teachers-tab" data-toggle="tab" href="#teachers" role="tab" aria-controls="teachers" aria-selected="false">
                                    <span><IoPersonSharp /></span> Teachers</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="reviews-tab" data-toggle="tab" href="#reviews" role="tab" aria-controls="reviews" aria-selected="false">
                                    <span><MdMessage /></span> Reviews</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="webinar-tab" data-toggle="tab" href="#webinar" role="tab" aria-controls="webinar" aria-selected="false">
                                    <span><CiStreamOn /></span> Webinar</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="quizes-tab" data-toggle="tab" href="#quizes" role="tab" aria-controls="quizes" aria-selected="false">
                                    <span><FaLightbulb /></span> Quizes</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="blogs-tab" data-toggle="tab" href="#blogs" role="tab" aria-controls="blogs" aria-selected="false">
                                    <span><FaBlog /></span> Blogs</a>
                            </li>

                            <li class="nav-item" onClick={logout}>
                                <a class="nav-link" id="logout-tab" data-toggle="tab" href="#logout" role="tab" aria-controls="logout" aria-selected="false">
                                    <span><IoIosLogOut /></span> Logout</a>
                            </li>

                        </ul>
                    </div>
                    {/* <!-- /.col-md-4 --> */}
                    <div class="col-md-10">
                        <div class="tab-content" id="experienceTabContent">

                            <div class="tab-pane fade show active text-left text-light" id="dashboard_home" role="tabpanel" aria-labelledby="dashborad-home-tab">
                                <DashboradHome />
                            </div>

                            <div class="tab-pane fade text-left text-light" id="courses" role="tabpanel" aria-labelledby="courses-tab">
                                <Courses />
                            </div>

                            <div class="tab-pane fade text-left text-light" id="modules" role="tabpanel" aria-labelledby="modules-tab">
                                <Modules />
                            </div>

                            <div class="tab-pane fade text-left text-light" id="Module-data" role="tabpanel" aria-labelledby="Module-data-tab">
                                <ModulesData />
                            </div>

                            <div class="tab-pane fade text-left text-light" id="addstudent" role="tabpanel" aria-labelledby="addstudent-tab">
                                <AddStudent />
                            </div>

                            <div class="tab-pane fade text-left text-light" id="orders" role="tabpanel" aria-labelledby="orders-tab">
                                <Orders />
                            </div>

                            <div class="tab-pane fade text-left text-light" id="teachers" role="tabpanel" aria-labelledby="teachers-tab">
                                <Teachers />
                            </div>
                            <div class="tab-pane fade text-left text-light" id="reviews" role="tabpanel" aria-labelledby="reviews-tab">
                                <Reviews />
                            </div>

                            <div class="tab-pane fade text-left text-light" id="webinar" role="tabpanel" aria-labelledby="webinar-tab">
                                <Webinar />
                            </div>

                            <div class="tab-pane fade text-left text-light" id="quizes" role="tabpanel" aria-labelledby="quizes-tab">
                                <Quizes />
                            </div>

                            <div class="tab-pane fade text-left text-light" id="blogs" role="tabpanel" aria-labelledby="blogs-tab">
                                <Blogs />
                            </div>

                        </div>
                        {/* <!--tab content end--> */}
                    </div>
                    {/* <!-- col-md-8 end --> */}
                </div>
            </section>
        </div>
    )
}

export default Dashboard
